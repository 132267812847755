import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "app/hooks";
import { persistor } from "app/store";
import Avatar from "components/Avatar/Avatar";
import {
  DashboardIcon,
  HelpIcon,
  LogoutIcon,
  SettingsIcon,
} from "components/icons";
import Logo from "components/Logo/Logo";
import Menu from "components/Menu/Menu";
import { Items } from "components/Menu/Menu.d";
import NavButton from "components/NavButton/NavButton";
import { logout } from "features/Auth/authSlice";
import useWindowSize from "shared/hooks/useWindowSize";

import s from "./Navbar.module.scss";

import config from "config/config";

const { routes } = config;

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    persistor.purge();
    dispatch(logout());
  };

  const items: Items[] = [
    {
      id: "1",
      icon: <LogoutIcon />,
      label: "Logout",
      onClick: handleLogout,
      error: false,
    },
  ];

  const [mobileButtons, desktopButtons] = useMemo(() => {
    const wallet = {
      to: routes.home,
      icon: <DashboardIcon />,
      text: t("wallet"),
    };
    const help = {
      to: routes.help,
      icon: <HelpIcon />,
      text: t("help"),
      external: true,
    };
    const settings = {
      to: routes.settings,
      icon: <SettingsIcon />,
      text: t("more"),
    };
    const mobile = [wallet, help, settings];
    const desktop = [wallet, help, settings];
    return [mobile, desktop];
  }, [t]);

  return (
    <div className={s.root}>
      {isMobile ? (
        <>
          {mobileButtons.map((button) => (
            <NavButton {...button} key={button.to} />
          ))}
          <button onClick={() => setShowMenu(!showMenu)}>
            <Avatar size="medium" src={config.defaultUserImageUrl} />
          </button>
          {showMenu && (
            <div className={s.menuWrapper}>
              <Menu items={items} />
            </div>
          )}
        </>
      ) : (
        <>
          <Logo white />
          <div className={s.content}>
            <div className={s.buttonsContainer}>
              {desktopButtons.map((button) => (
                <NavButton {...button} key={button.to} />
              ))}
            </div>
            <button
              className={s.menuButton}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              <Avatar size="medium" src={config.defaultUserImageUrl} />
            </button>
            {showMenu && (
              <div className={s.menuWrapper}>
                <Menu items={items}></Menu>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
