const EulaContent = () => {
  return (
    <>
      <h2>Section 1. Scope and Applicability</h2>
      <p>
        This End User License Agreement (&quot;EULA&quot;) between You and Crown
        Sterling covers Your use of the Crown Sterling Wallet Software
        (&quot;Crown Sterling Wallet Technology&quot;). This document also
        incorporates any Product Specific Terms that may apply to the Crown
        Sterling Wallet Technology You acquire. Definitions of capitalized terms
        are in Section 12 (Definitions).
      </p>
      <p>
        You agree to be bound by the terms of this EULA through (a) Your
        download, installation, or use of the Crown Sterling Wallet Technology;
        or (b) Your express agreement to this EULA.
      </p>
      <p>
        If You do not have authority to enter into this EULA or You do not agree
        with its terms, do not use the Crown Sterling Wallet Technology.
      </p>
      <h2>Section 2. Using Crown Sterling Wallet Technology</h2>
      <p>
        2.1. License and Right to Use. Crown Sterling grants You a
        non-exclusive, non-transferable, revocable license to use the Crown
        Sterling Wallet Technology, as acquired from Crown Sterling, for Your
        direct benefit during the term of the copyright of the Crown Sterling
        Wallet Technology and as set out in this EULA (collectively, the
        &quot;Usage Rights&quot;).
      </p>
      <p>
        2.2. Use by Third Parties. You may permit Authorized Third Parties to
        exercise the Usage Rights on Your behalf; provided that You are
        responsible for (a) ensuring that such Authorized Third Parties comply
        with this EULA and (b) any breach of this EULA by such Authorized Third
        Parties.
      </p>
      <p>
        2.3. Beta and Trial Use. If Crown Sterling grants You Usage Rights in
        the applicable Crown Sterling Wallet Technology on a trial, evaluation,
        or beta basis (&quot;Evaluation Software and Services&quot;), You may
        only use the Evaluation Software and Services on a temporary basis for
        the period limited by the license key or specified by Crown Sterling in
        writing. If there is no period identified, such use is limited to 30
        days after the Evaluation Software and Services are made available to
        You. Crown Sterling, in its discretion, may stop providing the
        Evaluation Software and Services at any time, at which point You will no
        longer have access to any related data, information and files and must
        immediately cease using the Crown Sterling Wallet Technology. The
        Evaluation Software and Services may not have been subject to Crown
        Sterling&apos;s usual testing and quality assurance processes and may
        contain bugs, errors or other issues and Crown Sterling hereby
        specifically disclaims any and all damage to You caused as a result of
        Your use of the Evaluation Software and Service. Except where agreed to
        in writing by Crown Sterling, You will not put Evaluation Software and
        Services into production use. Crown Sterling provides Evaluation
        Software and Services &quot;AS-IS&quot; without support or any express
        or implied warranty or indemnity for any problems or issues, and Crown
        Sterling will not have any liability relating to Your use of the
        Evaluation Software and Services.
      </p>
      <p>
        2.4. Upgrades or Additional Copies. You may only use Upgrades or
        additional copies of the Crown Sterling Wallet Technology beyond Your
        license Usage Rights if You have (a) acquired such rights under an end
        user license agreement covering the applicable software.
      </p>
      <h2>Section 3. Additional Conditions of Use</h2>
      <p>
        3.1. Crown Sterling Wallet Technology Generally. Unless expressly agreed
        by Crown Sterling, You may not (a) transfer, sell, sublicense, monetize
        or make the functionality of any Crown Sterling Wallet Technology
        available to any third party; (b) remove, modify or conceal any product
        identification, copyright, proprietary, intellectual property notices or
        other marks; (c) reverse engineer, decompile, decrypt, disassemble,
        modify or make derivative works of the Crown Sterling Wallet Technology;
        or (d) use Crown Sterling Content other than as part of Your permitted
        Usage Rights of the Crown Sterling Wallet Technology.
      </p>
      <p>
        3.2. Evolving Crown Sterling Wallet Technology. Crown Sterling may end
        the life of Crown Sterling Wallet Technology, including component
        functionality (&quot;EOL&quot;), by providing written notice on
        crownsterling.io. In such event, Crown Sterling will use commercially
        reasonable efforts to transition You to a substantially similar Crown
        Sterling Wallet Technology.
      </p>
      <p>
        3.3. Protecting Account Access. You will keep all account information up
        to date, use reasonable means to protect Your account information,
        passwords and other login credentials, and promptly notify Crown
        Sterling of any known or suspected unauthorized use of or access to Your
        account.
      </p>
      <p>
        3.4. Use with Third-Party Products. If You use the Crown Sterling Wallet
        Technology together with third-party products, such use is at Your risk.
        You are responsible for complying with any third-party provider terms,
        including its privacy policy. Crown Sterling does not provide support or
        guarantee ongoing integration support for products that are not a native
        part of the Crown Sterling Wallet Technology.
      </p>
      <h2>Section 4. [Reserved]</h2>
      <h2>Section 5. Confidential Information and Use of Data</h2>
      <p>
        Recipient will hold in confidence and use no less than reasonable care
        to avoid disclosure of any Confidential Information to any third party,
        except for its employees, affiliates and contractors who have a need to
        know (&quot;Permitted Recipients&quot;). Recipient: (a) must ensure that
        its Permitted Recipients are subject to written confidentiality
        obligations no less restrictive than the Recipient&apos;s obligations
        under this EULA, and (b) is liable for any breach of this Section by its
        Permitted Recipients. Such nondisclosure obligations will not apply to
        information that: (i) is known by Recipient without confidentiality
        obligations; (ii) is or has become public knowledge through no fault of
        Recipient; or (iii) is independently developed by Recipient. Recipient
        may disclose Discloser&apos;s Confidential Information if required under
        a regulation, law or court order; provided, that Recipient provides
        prior notice to Discloser (to the extent legally permissible) and
        reasonably cooperates, at Discloser&apos;s expense, regarding protective
        actions pursued by Discloser. Upon the reasonable request of Discloser,
        Recipient will either return, delete or destroy all Confidential
        Information of Discloser and certify the same.
      </p>
      <h2>Section 6. Ownership</h2>
      <p>
        Except where agreed in writing, nothing in this EULA transfers ownership
        in, or grants any license to, any intellectual property rights. You
        retain any ownership of Your content and Crown Sterling retains
        ownership of the Crown Sterling Wallet Technology and Crown Sterling
        Content. Crown Sterling may use any feedback You provide in connection
        with Your use of the Crown Sterling Wallet Technology as part of its
        business operations.
      </p>
      <h2>Section 7. Indemnification</h2>
      <p>
        7.1. Exclusive Remedies. If any third-party claim is made against You
        that Your valid use of Crown Sterling Wallet Technology under Your Usage
        Rights infringes a third party&apos;s patent, copyright or registered
        trademark (the &quot;IP Claim&quot;) and prevents Your exercise of the
        Usage Rights, as your exclusive remedy, Crown Sterling will either
        procure for You the right to continue using the Crown Sterling Wallet
        Technology or replace or modify the Crown Sterling Wallet Technology
        with functionality that is at least equivalent.
      </p>
      <p>
        7.2. Exclusions. Crown Sterling has no obligation with respect to any IP
        Claim based on: (a) Your modification of any Crown Sterling Wallet
        Technology or modification by a third party; (b) the amount or duration
        of use made of the Crown Sterling Wallet Technology, revenue You earned,
        or services You offered; (c) combination, operation, or use of Crown
        Sterling Wallet Technology with non-Crown Sterling products, software or
        business processes; (d) Your failure to modify or replace Crown Sterling
        Wallet Technology as required by Crown Sterling; or (e) any Crown
        Sterling Wallet Technology provided on a beta or evaluation basis.
      </p>
      <p>
        7.3. This Section 7 states Crown Sterling&apos;s entire obligation and
        Your exclusive remedy regarding any IP Claims against You.
      </p>
      <h2>Section 8. Warranties, Representations and Risks</h2>
      <p>
        8.1. Performance. UNLESS SEPARATELY STATED, THE CROWN STERLING WALLET
        TECHNOLOGY AND/OR INTELLECTUAL PROPERTY PROVIDED BY CROWN STERLING
        (WHETHER INCLUDED WITH THE CROWN STERLING WALLET TECHNOLOGY, DOWNLOADED,
        OR OTHERWISE OBTAINED) IS PROVIDED &quot;AS IS&quot; AND ON AN &quot;AS
        AVAILABLE&quot; BASIS, WITHOUT WARRANTIES OF ANY KIND FROM CROWN
        STERLING, EITHER EXPRESS OR IMPLIED. TO THE FULLEST POSSIBLE EXTENT
        PURSUANT TO APPLICABLE LAW, CROWN STERLING DISCLAIMS ALL WARRANTIES
        EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY OR WORKMANSHIP LIKE
        EFFORT, FITNESS FOR A PARTICULAR PURPOSE, RELIABILITY OR AVAILABILITY,
        ACCURACY, LACK OF VIRUSES, QUIET ENJOYMENT, NON-INFRINGEMENT OF
        THIRD-PARTY RIGHTS OR OTHER VIOLATIONS OF RIGHTS. SOME JURISDICTIONS DO
        NOT ALLOW EXCLUSIONS OR LIMITATIONS OF IMPLIED WARRANTIES, SO SOME OF
        THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU. NO ADVICE OR
        INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM CROWN
        STERLING OR ITS AFFILIATES SHALL BE DEEMED TO ALTER THIS DISCLAIMER BY
        CROWN STERLING OF WARRANTY REGARDING THE SOFTWARE AND/OR INTELLECTUAL
        PROPERTY OR EULA, OR TO CREATE ANY WARRANTY OF ANY SORT FROM CROWN
        STERLING.
      </p>
      <p>
        8.2. Malicious Code. Crown Sterling will use commercially reasonable
        efforts to deliver the Crown Sterling Wallet Technology free of
        Malicious Code.
      </p>
      <p>
        8.3. Qualifications. Section 8.2 does not apply if the Crown Sterling
        Wallet Technology or the equipment on which it is authorized for use:
        (a) has been altered, except by Crown Sterling or its authorized
        representative; (b) has been subjected to abnormal physical conditions,
        accident or negligence, or installation or use inconsistent with this
        EULA or Crown Sterling&apos;s instructions; (c) is acquired on a beta or
        evaluation basis; (d) is not a Crown Sterling-branded service; or (e)
        has not been provided by Crown Sterling. Upon Your prompt written
        notification to Crown Sterling during the warranty period of Crown
        Sterling&apos;s breach of this Section 8, Your sole and exclusive remedy
        (unless otherwise required by applicable law) is (i) repair or
        replacement of the applicable Crown Sterling Wallet Technology.
      </p>
      <p>
        8.4. EXCEPT AS EXPRESSLY STATED IN THIS SECTION, CROWN STERLING DOES NOT
        WARRANT THAT USE OF THE CROWN STERLING WALLET TECHNOLOGY WILL BE
        UNINTERRUPTED OR ERROR-FREE. YOU ACCEPT THAT SOFTWARE IN GENERAL IS
        PRONE TO BUGS AND FLAWS WITHIN AN ACCEPTABLE LEVEL AS DETERMINED BY THE
        INDUSTRY. IF YOU ARE A CONSUMER, YOU MAY HAVE LEGAL RIGHTS IN YOUR
        COUNTRY OF RESIDENCE THAT PROHIBIT THE LIMITATIONS SET OUT IN THIS
        SECTION FROM APPLYING TO YOU, AND, WHERE PROHIBITED, THEY WILL NOT
        APPLY.
      </p>
      <p>
        8.5. RISKS CROWN STERLING WALLET TECHNOLOGY RELIES ON EMERGING
        TECHNOLOGIES, SUCH AS POLKADOT. CROWN STERLING WALLET TECHNOLOGY MAY BE
        SUBJECT TO INCREASED RISK THROUGH YOUR POTENTIAL MISUSE OF THINGS SUCH
        AS PUBLIC/PRIVATE KEY CRYPTOGRAPHY OR FAILING TO PROPERLY UPDATE OR RUN
        SOFTWARE TO ACCOMMODATE UNDERLYING PROTOCOL UPGRADES. BY USING THE CROWN
        STERLING WALLET TECHNOLOGY, YOU EXPLICITLY ACKNOWLEDGE AND ACCEPT THESE
        HEIGHTENED RISKS. YOU REPRESENT THAT YOU ARE FINANCIALLY AND TECHNICALLY
        SOPHISTICATED ENOUGH TO UNDERSTAND THE INHERENT RISKS ASSOCIATED WITH
        USING CRYPTOGRAPHIC AND BLOCKCHAIN-BASED SYSTEMS AND UPGRADING YOUR
        SOFTWARE AND PROCESSES TO ACCOMMODATE UNDERLYING PROTOCOL UPGRADES, AND
        THAT YOU HAVE A WORKING KNOWLEDGE OF THE USAGE AND INTRICACIES OF
        DIGITAL ASSETS. IN PARTICULAR, YOU UNDERSTAND THAT WE DO NOT OPERATE THE
        POLKADOT RELAY CHAIN OR A POLKADOT PARACHAIN, AND THE BLOCKCHAIN THAT
        SUPPORTS THE WALLET FUNCTIONS AS A SOLO CHAIN. YOU FURTHER UNDERSTAND
        THAT UNDERLYING BLOCKCHAIN PROTOCOLS PRESENT THEIR OWN RISKS OF USE,
        THAT SUPPORTING OR PARTICIPATING IN THE UNDERLYING PROTOCOL MAY RESULT
        IN LOSSES IF YOUR PARTICIPATION VIOLATES CERTAIN PROTOCOL RULES, THAT
        BLOCKCHAIN-BASED TRANSACTIONS ARE IRREVERSIBLE, THAT YOUR CREDENTIALS
        MUST BE KEPT SECRET AT ALL TIMES, AND THAT YOU ARE SOLELY RESPONSIBLE
        FOR ANY LOSS OF FUNDS DUE TO YOUR FAILURE TO KEEP YOUR CREDENTIALS
        SECRET. YOU ALSO UNDERSTAND THAT YOU ARE ALSO SOLELY RESPONSIBLE FOR ANY
        APPROVALS OR PERMISSIONS TO MESSAGES OR TRANSACTIONS YOU PROVIDE THROUGH
        THE CROWN STERLING WALLET TECHNOLOGY.
      </p>
      <p>
        YOU FURTHER UNDERSTAND AND ACCEPT THAT DIGITAL TOKENS PRESENT MARKET
        VOLATILITY RISK, TECHNICAL SOFTWARE RISKS, REGULATORY RISKS, AND
        CYBERSECURITY RISKS. YOU UNDERSTAND THAT THE TOKEN PRICE, COST AND SPEED
        OF A BLOCKCHAIN-BASED SYSTEM IS VARIABLE, THAT TOKEN PRICE AND/OR COST
        MAY INCREASE OR DECEASE DRAMATICALLY AT ANY TIME, AND THAT TOKEN PRICE,
        COST AND SPEED IS NOT WITHIN THE CAPABILITY OF CROWN STERLING TO
        CONTROL. YOU UNDERSTAND THAT PROTOCOL UPGRADES MAY INADVERTENTLY CONTAIN
        BUGS OR SECURITY VULNERABILITIES THAT MAY RESULT IN LOSS OF
        FUNCTIONALITY AND ULTIMATELY FUNDS.
      </p>
      <p>
        YOU UNDERSTAND AND ACCEPT THAT THE WALLET OPERATES BY CONNECTING
        DIRECTLY TO THE &quot;CROWN STERLING&quot; SOLO CHAIN THAT CROWN
        STERLING CURRENTLY OPERATES AND UNDERSTAND THAT A SOLO CHAIN DOES NOT
        BENEFIT FROM THE EXTENDED SECURITY CAPABILITIES OF THE POLKADOT NETWORK
        AND RUNS ITS OWN CONSENSUS, WITHOUT OFFERING CONSTANT COMMUNICATION WITH
        THE POLKADOT RELAY CHAIN. YOU AGREE THAT YOU ALONE, AND NOT CROWN
        STERLING, IS RESPONSIBLE FOR ANY TRANSACTIONS THAT YOU ENGAGE IN WITH
        REGARD TO SUPPORTING ANY UNDERLYING BLOCKCHAIN PROTOCOL WHETHER THROUGH
        TRANSACTION VALIDATION OR OTHERWISE, OR ANY TRANSACTIONS THAT YOU ENGAGE
        IN WITH ANY THIRD-PARTY-DEVELOPED SMART CONTRACT OR TOKEN, INCLUDING
        TOKENS THAT WERE CREATED BY A THIRD PARTY FOR THE PURPOSE OF
        FRAUDULENTLY MISREPRESENTING AFFILIATION WITH ANY BLOCKCHAIN PROJECT.
        YOU AGREE THAT CROWN STERLING IS NOT RESPONSIBLE FOR THE REGULATORY
        STATUS OR TREATMENT OF ANY DIGITAL ASSETS. YOU EXPRESSLY ASSUME FULL
        RESPONSIBILITY FOR ALL OF THE RISKS OF ACCESSING AND USING THE CROWN
        STERLING WALLET TECHNOLOGY TO INTERACT WITH UNDERLYING BLOCKCHAIN
        PROTOCOLS.
      </p>
      <h2>Section 9. Liability</h2>
      <p>
        CROWN STERLING WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
        OUT OF OR RELATING TO THE USE OR THE INABILITY TO USE THE CROWN STERLING
        WALLET TECHNOLOGY AND/OR INTELLECTUAL PROPERTY OR COMBINE THE CROWN
        STERLING WALLET TECHNOLOGY WITH ANY THIRD PARTY APPLICATION, ITS CONTENT
        OR FUNCTIONALITY, INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR
        RELATED TO ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAY IN OPERATION
        OR TRANSMISSION, COMPUTER VIRUS, FAILURE TO CONNECT, NETWORK CHARGES, OR
        IN-APP PURCHASES. CROWN STERLING DISCLAIMS ANY RESPONSIBILITY FOR ANY
        DISCLOSURE OF INFORMATION BY (OR ANY OTHER PRACTICES OF) ANY THIRD-PARTY
        APPLICATION PROVIDER.
      </p>
      <p>
        IN ADDITION, NEITHER PARTY WILL BE LIABLE FOR INDIRECT, INCIDENTAL,
        EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES; LOSS OR CORRUPTION OF DATA
        OR INTERRUPTION OR LOSS OF BUSINESS; OR LOSS OF REVENUES, PROFITS,
        GOODWILL OR ANTICIPATED SALES OR SAVINGS. THE MAXIMUM AGGREGATE
        LIABILITY OF EACH PARTY UNDER THIS EULA IS LIMITED TO ONE HUNDRED
        DOLLARS ($100.00).
      </p>
      <p>
        These limitations of liability do not apply to liability arising from
        (a) Your failure to pay all amounts due; or (b) Your breach of Sections
        2.1 (License and Right to Use), 3.1 (Crown Sterling Wallet Technology
        Generally) or 11.8 (Export). This limitation of liability applies
        whether the claims are in warranty, contract, tort (including
        negligence), infringement or otherwise, even if either party has been
        advised of the possibility of such damages. Nothing in this EULA limits
        or excludes any liability that cannot be limited or excluded under
        applicable law. This limitation of liability is cumulative and not per
        incident.
      </p>
      <h2>Section 10. Termination and Suspension</h2>
      <p>
        10.1. Suspension. Crown Sterling may immediately suspend Your Usage
        Rights if You breach Sections 2.1 (License and Right to Use), 3.1 (Crown
        Sterling Wallet Technology Generally) or 11.8 (Export).
      </p>
      <p>
        10.2. Termination. If a party materially breaches this EULA and does not
        cure that breach within 30 days after receipt of written notice of the
        breach, the non-breaching party may terminate this EULA for cause. Crown
        Sterling may immediately terminate this EULA if You breach Sections 2.1
        (License and Right to Use), 3.1 (Crown Sterling Wallet Technology
        Generally) or 11.8 (Export). Upon termination of the EULA, You must stop
        using the Crown Sterling Wallet Technology and destroy any copies of the
        Crown Sterling Wallet Technology and Confidential Information within
        Your control.
      </p>
      <h2>Section 11. General Provisions</h2>
      <p>
        11.1 Survival. Sections 4, 5, 6, 8, 9, 10 and 11 survive termination or
        expiration of this EULA.
      </p>
      <p>
        11.2 Third-Party Beneficiaries. This EULA does not grant any right or
        cause of action to any third party.
      </p>
      <p>
        11.3 Assignment and Subcontracting. Except as set out below, neither
        party may assign or novate this EULA in whole or in part without the
        other party&apos;s express written consent. Crown Sterling may (a) by
        written notice to You, assign or novate this EULA in whole or in part to
        an Affiliate of Crown Sterling, or otherwise as part of a sale or
        transfer of any part of its business; or (b) subcontract any performance
        associated with the Crown Sterling Wallet Technology to third parties;
        provided, that such subcontract does not relieve Crown Sterling of any
        of its obligations under this EULA.
      </p>
      <p>
        11.4 U.S. Government End Users. The Crown Sterling Wallet Technology and
        Documentation are deemed to be &quot;commercial computer software&quot;
        and &quot;commercial computer software documentation&quot; pursuant to
        FAR 12.212 and DFARS 227.7202. All U.S. Government end users acquire the
        Crown Sterling Wallet Technology and Documentation with only those
        rights set forth in this EULA. Any provisions that are inconsistent with
        federal procurement regulations are not enforceable against the U.S.
        Government.
      </p>
      <p>
        11.5 Crown Sterling Partner Transactions. If You obtain the Crown
        Sterling Wallet Technology from a Crown Sterling Partner, the terms of
        this EULA apply to Your use of that Crown Sterling Wallet Technology and
        prevail over any inconsistent provisions in Your agreement with the
        Crown Sterling Partner.
      </p>
      <p>
        11.6 Modifications to the EULA. Crown Sterling may change this EULA or
        any of its components by updating this EULA on crownsterling.io. Changes
        to the EULA apply to any Usage Rights acquired or renewed after the date
        of modification.
      </p>
      <p>
        11.7 Compliance with Laws. Each party will comply with all laws and
        regulations applicable to their respective obligations under this EULA.
        Crown Sterling may restrict the availability of the Crown Sterling
        Wallet Technology in any particular location or modify or discontinue
        features to comply with applicable laws and regulations.
      </p>
      <p>
        If You use the Crown Sterling Wallet Technology in a location with local
        laws requiring a designated entity to be responsible for collection of
        data about individual end users and transfer of data outside of that
        jurisdiction (e.g., Russia and China), You acknowledge that You are the
        entity responsible for complying with such laws.
      </p>
      <p>
        11.8 Export. Crown Sterling&apos;s Crown Sterling Wallet Technology,
        other software, products, technology and services (collectively the
        &quot;Crown Sterling Products&quot;) are subject to U.S. and local
        export control and sanctions laws. You acknowledge and agree to the
        applicability of and Your compliance with those laws, and You will not
        receive, use, transfer, export or re-export any Crown Sterling Products
        in a way that would cause Crown Sterling to violate those laws. You also
        agree to obtain any required licenses or authorizations.
      </p>
      <p>
        11.9 Governing Law and Venue. This EULA, and any disputes arising from
        it, will be governed exclusively by the law of the State of California,
        USA, and without regard to conflicts of laws, rules or the United
        Nations Convention on the International Sale of Goods. The U.S. federal
        and state courts located in California will have exclusive jurisdiction
        to adjudicate any dispute arising out of or relating to the EULA or its
        formation, interpretation or enforcement. Each party hereby consents and
        submits to the exclusive jurisdiction of such courts. Regardless of the
        governing law, either party may seek interim injunctive relief in any
        court of appropriate jurisdiction with respect to any alleged breach of
        Crown Sterling&apos;s intellectual property or proprietary rights.
      </p>
      <p>
        11.10 Notice. Any notice delivered by Crown Sterling to You under this
        EULA will be delivered via email, regular mail or postings on
        crownsterling.io. Notices to Crown Sterling should be sent to Crown
        Sterling Limited LLC, Head of Legal, 4040 MacArthur Blvd, Ste 310, CA
        92660 unless this EULA, applicable Product Specific Terms or an order
        specifically allows other means of notice.
      </p>
      <p>
        11.11 Force Majeure. Except for payment obligations, neither party will
        be responsible for failure to perform its obligations due to an event or
        circumstances beyond its reasonable control.
      </p>
      <p>
        11.12 No Waiver. Failure by either party to enforce any right under this
        EULA will not waive that right.
      </p>
      <p>
        11.13 Severability. If any portion of this EULA is not enforceable, it
        will not affect any other terms.
      </p>
      <p>
        11.14 Entire agreement. This EULA is the complete agreement between the
        parties with respect to the subject matter of this EULA and supersedes
        all prior or contemporaneous communications, understandings or
        agreements (whether written or oral).
      </p>
      <p>
        11.15 Order of Precedence. If there is any conflict between this EULA
        and any Product Specific Terms expressly referenced in this EULA, the
        order of precedence is: (a) such Product Specific Terms; (b) this EULA
        (excluding the Product Specific Terms and any Crown Sterling policies);
        then (c) any applicable Crown Sterling policy expressly referenced in
        this EULA.
      </p>
      <h2>Section 12. Definitions</h2>
      <p>
        &quot;Affiliate&quot; means any corporation or company that directly or
        indirectly controls, or is controlled by, or is under common control
        with the relevant party, where &quot;control&quot; means to: (a) own
        more than 50% of the relevant party; or (b) be able to direct the
        affairs of the relevant party through any lawful means (e.g., a contract
        that allows control).
      </p>
      <p>
        &quot;Authorized Third Parties&quot; means Your Users, Your Affiliates,
        Your third-party service providers, and each of their respective Users
        permitted to access and use the Crown Sterling Wallet Technology on Your
        behalf as part of Your Usage Rights.
      </p>
      <p>
        &quot;Crown Sterling&quot; &quot;we&quot; &quot;our&quot; or
        &quot;us&quot; means Crown Sterling Limited LLC, a Delaware limited
        liability company, or its applicable Affiliate(s).
      </p>
      <p>
        &quot;Crown Sterling Content&quot; means any (a) content or data
        provided by Crown Sterling to You as part of Your use of the Crown
        Sterling Wallet Technology and (b) content or data that the Crown
        Sterling Wallet Technology generates or derives in connection with Your
        use. Crown Sterling Content includes geographic and domain information,
        rules, signatures, threat intelligence and data feeds and Crown
        Sterling&apos;s compilation of suspicious URLs.
      </p>
      <p>
        &quot;Confidential Information&quot; means non-public proprietary
        information of the disclosing party (&quot;Discloser&quot;) obtained by
        the receiving party (&quot;Recipient&quot;) in connection with this
        EULA, that is (a) conspicuously marked as confidential or, if verbally
        disclosed, is summarized in writing to the Recipient within 14 days and
        marked as confidential; or (b) is information that by its nature should
        reasonably be considered confidential whether disclosed in writing or
        verbally.
      </p>
      <p>
        &quot;Delivery Date&quot; means the earlier of the date Crown Sterling
        Wallet Technology is made available for download or installation, or the
        date that Crown Sterling ships the tangible media containing the Crown
        Sterling Wallet Technology.
      </p>
      <p>
        &quot;Documentation&quot; means the technical specifications and usage
        materials officially published by Crown Sterling specifying the
        functionalities and capabilities of the applicable Crown Sterling Wallet
        Technology.
      </p>
      <p>
        &quot;Malicious Code&quot; means code that is designed or intended to
        disable or impede the normal operation of, or provide unauthorized
        access to, networks, systems or software other than as intended by the
        Crown Sterling Wallet Technology (for example, as part of some of Crown
        Sterling&apos;s security products).
      </p>
      <p>
        &quot;Product Specific Terms&quot; means additional product related
        terms applicable to the Crown Sterling Wallet Technology You acquire as
        set out at www.crownsterling.io.
      </p>
      <p>
        &quot;Upgrades&quot; means all updates, upgrades, bug fixes, error
        corrections, enhancements and other modifications to the Crown Sterling
        Wallet Technology.
      </p>
      <p>
        &quot;User&quot; means the individuals (including contractors or
        employees) permitted to access and use the Crown Sterling Wallet
        Technology on Your behalf as part of Your Usage Rights.
      </p>
      <p>
        &quot;You&quot; means the individual or legal entity utilizing the Crown
        Sterling Wallet Technology.
      </p>
    </>
  );
};

export default EulaContent;
