// Need to use the React-specific entry point to allow generating React hooks
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import {
  BaseQueryApi,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import { RootState } from "app/store";

import config from "config/config";

type PrepareHeadersType =
  | ((
      headers: Headers,
      api: Pick<
        BaseQueryApi,
        "getState" | "extra" | "endpoint" | "type" | "forced"
      >,
    ) => MaybePromise<void | Headers>)
  | undefined;

const prepareHeaders: PrepareHeadersType = (headers, { getState }) => {
  const withoutAuth = Boolean(headers.get("no-auth"));
  if (withoutAuth) {
    headers.delete("no-auth");
    return headers;
  }
  const authToken = (getState() as RootState).auth.authToken;

  if (authToken) {
    headers.set("Authorization", `${authToken}`);
  }
  return headers;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders,
  }),
  endpoints: () => ({}),
});
