/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type ResetPasswordResponse = {
  success: boolean;
};

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<ResetPasswordResponse, string>({
    query: (email) => {
      return {
        url: config.api.resetPasswordInit,
        method: "POST",
        body: {
          email: email,
        },
      };
    },
    transformResponse: (response: string, meta: any) => {
      const success = meta.response.status === 200;
      return {
        success,
      };
    },
  });
