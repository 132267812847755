import { useMemo } from "react";

import { useAppSelector } from "app/hooks";
import {
  selectAddress,
  selectBalance,
  selectTransactions,
} from "features/Wallet/walletSlice";
import { stripValue } from "shared/utils/csov";

import BalanceCard, { CardType } from "./BalanceCard";

import styles from "./BalanceCards.module.scss";

const BalanceCardContainer = () => {
  const balance = useAppSelector(selectBalance);
  const transactions = useAppSelector(selectTransactions);
  const address = useAppSelector(selectAddress);

  const total = useMemo(() => {
    let incomingSum = 0;
    let outgoingSum = 0;
    transactions.forEach((transaction) => {
      const transactionAmountToNumber = Number(transaction.amount);
      if (transaction.to === address) {
        incomingSum += transactionAmountToNumber;
      } else {
        outgoingSum += transactionAmountToNumber;
      }
    });

    return {
      incoming: stripValue(incomingSum),
      outgoing: stripValue(outgoingSum),
    };
  }, [transactions, address]);

  return (
    <div className={styles.cardContainer}>
      <BalanceCard type={CardType.BALANCE} amount={stripValue(balance) ?? 0} />
      <BalanceCard type={CardType.INCOMING} amount={total.incoming} />
      <BalanceCard type={CardType.OUTGOING} amount={total.outgoing} />
    </div>
  );
};

export default BalanceCardContainer;
