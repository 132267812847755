import { Link } from "react-router-dom";

import { ITextlink } from "./Textlink.d";

import s from "./Textlink.module.scss";

const Textlink: React.FC<ITextlink> = ({ href, text, to, onClick }) => {
  if (to) {
    return (
      <Link className={s.root} to={to}>
        {text ?? to}
      </Link>
    );
  }

  if (href) {
    return (
      <a className={s.root} href={href}>
        {text ?? href}
      </a>
    );
  }

  return (
    <button className={s.root} onClick={onClick}>
      {text}
    </button>
  );
};

export default Textlink;
