import cx from "classnames";

import { IAvatar } from "./Avatar.d";

import s from "./Avatar.module.scss";

const Avatar: React.FC<IAvatar> = ({ size = "small", src }) => {
  return (
    <div className={cx(s.root, s[size])}>
      <img src={src} width="100%" height="100%" />
    </div>
  );
};

export default Avatar;
