import { toast } from "react-toastify";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import { PURGE } from "redux-persist";

import { persistor, RootState } from "app/store";
import { registerApi } from "features/api/services/registerUsers";
import { sessionApi } from "features/api/services/session";
import { userApi } from "features/api/services/updateUser";
import { walletApi } from "features/api/services/wallet";
import { getAllUserDetails } from "features/Wallet/walletSlice";
import { ApiError, User } from "shared/types/common.d";
import { EULA_ERROR_CODE } from "shared/utils/constants";
import {
  clearSessionStorage,
  saveTokenToSessionStorage,
} from "shared/utils/storage";

import i18n from "../../i18n";

import config from "config/config";

type AuthState = {
  isLoggedIn: boolean;
  currentUser: User | null;
  authToken: string | null;
  address: string | null;
  hasAcceptedEula: boolean;
  eulaToken: string;
  eulaModalOpen: boolean;
};

const initialState: AuthState = {
  isLoggedIn: false,
  currentUser: null,
  authToken: null,
  address: null,
  hasAcceptedEula: true,
  eulaToken: "",
  eulaModalOpen: false,
};

type Credentials = {
  email: string;
  password: string;
};

type RegisterData = {
  firstName: string;
  lastName: string;
  country: string;
  phoneNumber: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

type ResetData = {
  token: string;
  password: string;
  email: string;
};

export const setupUser = createAsyncThunk(
  "auth/setupUser",
  async (_, { dispatch }) => {
    try {
      const user = await dispatch(
        walletApi.endpoints.getAccount.initiate(_, { forceRefetch: true }),
      ).unwrap();

      dispatch(setCurrentUser(user));
      await dispatch(getAccountAddress());
      await dispatch(getAllUserDetails());
      dispatch(setIsLoggedIn(true));
    } catch (err) {
      if (err === EULA_ERROR_CODE) {
        dispatch(triggerEulaFlow());
      }

      const apiError = err as ApiError;
      const data = apiError?.data;
      if (data === config.sessionToken.sessionExpired) {
        await persistor.purge();
        dispatch(logout());
        toast.error(data);
      }
    }
  },
);

export const acceptEula = createAsyncThunk(
  "auth/acceptEula",
  async (_, { getState, dispatch }) => {
    const rootState = getState() as RootState;
    const authToken = selectAuthToken(rootState);
    if (!authToken) return;

    await dispatch(userApi.endpoints.acceptEula.initiate(_)).unwrap();
  },
);

export const verifyToken = createAsyncThunk(
  "auth/verifyToken",
  async (token: string, { dispatch }) => {
    return dispatch(userApi.endpoints.verifyToken.initiate(token)).unwrap();
  },
);

export const resetPasswordInit = createAsyncThunk(
  "auth/resetPassword",
  async (email: string, { dispatch }) => {
    const response = await dispatch(
      userApi.endpoints.resetPasswordInit.initiate(email),
    ).unwrap();
    toast.success(i18n.t("resetPasswordInitSuccess"));
    return response;
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPasswordConfirmation",
  async (data: ResetData, { dispatch }) => {
    try {
      const response = await dispatch(
        userApi.endpoints.resetPassword.initiate(data),
      ).unwrap();
      toast.success(i18n.t("changePasswordSuccess"));
      return response;
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.data === config.sessionToken.sessionExpired) {
        await persistor.purge();
        dispatch(logout());
        toast.error(apiError.data, {
          toastId: "error",
        });
      }

      return false;
    }
  },
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (
    { newPassword, oldPassword }: { newPassword: string; oldPassword: string },
    { dispatch, getState },
  ) => {
    try {
      const rootState = getState() as RootState;
      const currentUser = selectCurrentUser(rootState);
      if (!currentUser?.email) {
        throw new Error("No data to display");
      }
      const response = await dispatch(
        userApi.endpoints.changePassword.initiate({ newPassword, oldPassword }),
      ).unwrap();
      // Logout user to ensure that they get a new session token
      // Temp fix until backend send new token back to us
      await persistor.purge();
      dispatch(logout());
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.data === config.sessionToken.sessionExpired) {
        await persistor.purge();
        dispatch(logout());
        toast.error(apiError.data, {
          toastId: "error",
        });
      }
    }
  },
);

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (
    {
      country,
      firstName,
      lastName,
      phoneNumber,
    }: {
      country: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
    },
    { dispatch, getState },
  ) => {
    try {
      const rootState = getState() as RootState;
      const currentUser = selectCurrentUser(rootState);
      if (!currentUser?.email) {
        throw new Error("No data to display");
      }

      const response = await dispatch(
        userApi.endpoints.updateUser.initiate({
          country,
          firstName,
          lastName,
          phoneNumber,
        }),
      ).unwrap();
      const user = await dispatch(
        walletApi.endpoints.getAccount.initiate(),
      ).unwrap();
      dispatch(setCurrentUser(user));

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.data === config.sessionToken.sessionExpired) {
        await persistor.purge();
        dispatch(logout());
        toast.error(apiError.data, {
          toastId: "error",
        });
      }
    }
    return false;
  },
);

export const login = createAsyncThunk(
  "auth/login",
  async (params: Credentials, { dispatch }) => {
    const token = await dispatch(
      sessionApi.endpoints.postSession.initiate(params),
    ).unwrap();
    saveTokenToSessionStorage(token);
    dispatch(setAuthToken(token));
    await dispatch(setupUser());
  },
);

export const getAccountAddress = createAsyncThunk(
  "auth/getAccountAddress",
  async (_, { dispatch }) => {
    try {
      const address = await dispatch(
        walletApi.endpoints.getAddress.initiate(_, { forceRefetch: true }),
      ).unwrap();
      return address;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.data === config.sessionToken.sessionExpired) {
        await persistor.purge();
        dispatch(logout());
        toast.error(apiError.data, {
          toastId: "error",
        });
      }
      return null;
    }
  },
);

export const register = createAsyncThunk(
  "auth/register",
  async (params: RegisterData, { dispatch }) => {
    const response = await dispatch(
      registerApi.endpoints.postRegisterUsersBasic.initiate(params),
    ).unwrap();
    return response;
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    triggerEulaFlow: (state) => {
      state.eulaModalOpen = true;
      state.hasAcceptedEula = false;
    },
    setEulaModalOpen: (state, action: PayloadAction<boolean>) => {
      state.eulaModalOpen = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    logout: () => {
      clearSessionStorage();
      localStorage.clear();
      userApi.util.resetApiState();
      walletApi.util.resetApiState();

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(getAccountAddress.fulfilled, (state, action) => {
      state.address = action.payload;
    });
    builder.addCase(acceptEula.fulfilled, (state) => {
      state.eulaModalOpen = false;
      state.hasAcceptedEula = true;
      state.eulaToken = "";
    });
    builder.addCase(setupUser.fulfilled, (state) => {
      state.isLoggedIn = true;
    });
    builder.addMatcher(
      sessionApi.endpoints.postSession.matchRejected,
      (_, { payload }) => {
        if (payload.error) {
          toast.error(payload.data);
        }
      },
    );
    builder.addMatcher(userApi.endpoints.acceptEula.matchRejected, () => {
      toast.error(t("eulaCouldNotBeAccepted"));
    });
  },
});

export const {
  setCurrentUser,
  setAuthToken,
  triggerEulaFlow,
  setIsLoggedIn,
  setEulaModalOpen,
  logout,
} = authSlice.actions;

export const selectCurrentUser = (state: RootState) => {
  return state.auth.currentUser;
};
export const selectIsEulaModalOpen = (state: RootState) => {
  return state.auth.eulaModalOpen;
};

export const selectAuthToken = (state: RootState) => {
  return state.auth.authToken;
};
export const selectCurrentUserAddress = (state: RootState) => {
  return state.auth.address;
};

export const selectIsLoggedIn = (state: RootState) => {
  return state.auth.isLoggedIn;
};

export const selectEulaState = (state: RootState) => {
  return state.auth.hasAcceptedEula;
};

export const selectEulaToken = (state: RootState) => {
  return state.auth.eulaToken;
};

export default authSlice.reducer;
