import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import IconButton from "components/IconButton/IconButton";
import { EyeCloseIcon, EyeOpenIcon } from "components/icons";
import Logo from "components/Logo/Logo";
import TextField from "components/Textfield/Textfield";
import Textlink from "components/Textlink/Textlink";
import {
  acceptEula,
  login,
  selectEulaState,
  selectIsEulaModalOpen,
  selectIsLoggedIn,
  setEulaModalOpen,
} from "features/Auth/authSlice";
import EulaModal from "features/Register/EulaDialog/EulaModal";
import BaseLayout from "layouts/BaseLayout/BaseLayout";

import { useLoginSchema } from "./useLoginSchema";

import s from "./Login.module.scss";

import config from "config/config";

const WIDTH = 268;

type FormData = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const { t } = useTranslation("account");
  const hasAcceptedEula = useAppSelector(selectEulaState);
  const isEulaModalOpen = useAppSelector(selectIsEulaModalOpen);
  const schema = useLoginSchema();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn || !hasAcceptedEula) {
      return;
    }
    navigate(config.routes.home);
  }, [hasAcceptedEula, isLoggedIn, navigate]);

  const eyeIcon = showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />;

  const onEulaModalClose = () => {
    dispatch(setEulaModalOpen(false));
  };
  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onSubmit = async () => {
    const { email, password } = getValues();
    dispatch(login({ email, password }));
  };

  const handleAcceptEula = async () => {
    const { email, password } = getValues();
    await dispatch(acceptEula());
    await dispatch(login({ email, password }));
  };

  return (
    <BaseLayout>
      <EulaModal
        isOpen={isEulaModalOpen}
        onClose={onEulaModalClose}
        onAccept={handleAcceptEula}
      />
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.header}>
            <Logo size={170} withBrand />
          </div>
          <div className={s.content}>
            <div className={s.userPassArea}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: WIDTH }}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("email")}
                      placeholder={t("email")}
                      error={!!errors.email}
                      helperText={errors?.email?.message?.toString()}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword ? "text" : "password"}
                      label={t("password")}
                      placeholder={t("password")}
                      error={!!errors.password}
                      helperText={errors?.password?.message?.toString()}
                      iconRight={
                        <IconButton
                          type="flat"
                          icon={eyeIcon}
                          onClick={toggleShowPassword}
                        />
                      }
                    />
                  )}
                />
                <div className={s.actionArea}>
                  <Button wide bold size="large">
                    {t("login")}
                  </Button>
                </div>
              </form>
            </div>
            <div className={s.backArea}>
              <div className={s.backAreaItem}>
                <span>{t("dontHaveAnAccount")}</span>
                <Textlink to={config.routes.register} text={"Register"} />
              </div>
              <div className={s.backAreaItem}>
                <Textlink
                  to={config.routes.resetPassword}
                  text={"Forgot Password?"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Login;
