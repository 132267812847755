import React, { useCallback, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";
import { COUNTRY_OPTIONS } from "const/countries";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import IconButton from "components/IconButton/IconButton";
import { EyeCloseIcon, EyeOpenIcon } from "components/icons";
import SectionHeader from "components/SectionHeader/SectionHeader";
import TextField from "components/Textfield/Textfield";
import textfieldStyles from "components/Textfield/Textfield.module.scss";
import {
  changePassword,
  selectCurrentUser,
  updateUser,
} from "features/Auth/authSlice";

import { customStyles } from "../Register/styles";

import { useUpdateUserSchema } from "./useUpdateUserSchema";

import s from "./Settings.module.scss";

type UserInfoInputs = {
  firstName: string;
  lastName: string;
  country: string;
  phoneNumber: string;
};

type ResetPasswordInputs = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~`[\]{}|:;"'<>,./?])(?!.*\s).{12,}$/,
      "Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
    ),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const eyeIconCurrent = useMemo(
    () => (showCurrentPassword ? <EyeOpenIcon /> : <EyeCloseIcon />),
    [showCurrentPassword],
  );
  const eyeIconNew = useMemo(
    () => (showNewPassword ? <EyeOpenIcon /> : <EyeCloseIcon />),
    [showNewPassword],
  );

  const toggleShowCurrentPassword = useCallback(() => {
    setShowCurrentPassword((prev) => !prev);
  }, []);

  const toggleShowNewPassword = useCallback(() => {
    setShowNewPassword((prev) => !prev);
  }, []);

  const {
    control: resetPasswordControl,
    handleSubmit: resetPasswordHandleSubmit,
    formState: { errors: resetPasswordErrors, isValid: isResetPasswordValid },
    reset,
  } = useForm<ResetPasswordInputs>({
    resolver: yupResolver(passwordSchema),
    mode: "onBlur",
  });

  const updateUserSchema = useUpdateUserSchema();

  const {
    control: userInfoControl,
    setValue,
    handleSubmit: updateUserInfoSubmit,
    formState: { errors: userInfoErrors, isValid: isUserInfoValid },
    watch,
  } = useForm<UserInfoInputs>({
    defaultValues: currentUser ?? {},
    mode: "onBlur",
    resolver: yupResolver(updateUserSchema),
  });

  const countryValue = watch("country");

  const selectedCountry = useMemo(
    () => COUNTRY_OPTIONS.find((c) => c.value === countryValue),
    [countryValue],
  );

  const phoneCode = useMemo(
    () => selectedCountry?.phoneCode ?? "",
    [selectedCountry],
  );
  const masks = useMemo(
    () =>
      selectedCountry
        ? Array.isArray(selectedCountry.phoneMask)
          ? selectedCountry.phoneMask
          : [selectedCountry.phoneMask]
        : [],
    [selectedCountry],
  );

  const transformMask = (mask: string) =>
    mask.split("").map((char) => (char === "#" ? /\d/ : char));

  const resetPassword: SubmitHandler<ResetPasswordInputs> = async (
    data: ResetPasswordInputs,
  ) => {
    setIsLoading(true);

    const newPassword = data.newPassword;
    const oldPassword = data.currentPassword;

    const isSuccessful = (
      await dispatch(changePassword({ newPassword, oldPassword }))
    ).payload;
    setIsLoading(false);
    if (isSuccessful) {
      toast.success(t`changePasswordSuccess`);
      setShowCurrentPassword(false);
      setShowNewPassword(false);
      reset({
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      });
    }
  };

  const updateUserInfo: SubmitHandler<UserInfoInputs> = async (
    data: UserInfoInputs,
  ) => {
    const { country, firstName, lastName, phoneNumber } = data;

    setIsLoadingUserInfo(true);
    const isSuccessful = (
      await dispatch(updateUser({ country, firstName, lastName, phoneNumber }))
    ).payload;

    setIsLoadingUserInfo(false);
    if (isSuccessful) {
      toast.success(t`updatedUserInfoSuccess`);
    }
  };

  return (
    <div className={s.root}>
      <div className={s.headerArea}>
        <SectionHeader title={t("profile")} action={<></>} />
        <form onSubmit={updateUserInfoSubmit(updateUserInfo)}>
          <Controller
            name="firstName"
            control={userInfoControl}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!userInfoErrors.firstName}
                helperText={userInfoErrors.firstName?.message}
                label="First Name"
              />
            )}
          />
          <Controller
            name="lastName"
            control={userInfoControl}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!userInfoErrors.lastName}
                helperText={userInfoErrors.lastName?.message}
                label="Last Name"
              />
            )}
          />
          <Controller
            name="country"
            control={userInfoControl}
            render={({ field: { onChange, value, ...field } }) => (
              <div>
                <Select
                  {...field}
                  value={COUNTRY_OPTIONS.find(
                    (option) => option.value === value,
                  )}
                  classNames={{
                    control: () => userInfoErrors.country && s.errorState,
                  }}
                  options={COUNTRY_OPTIONS}
                  isSearchable={true}
                  styles={customStyles}
                  onChange={(option) => {
                    onChange(option?.value);
                    setValue("phoneNumber", option?.phoneCode ?? "");
                  }}
                  onBlur={field.onBlur}
                />
                {userInfoErrors.country && (
                  <p className={s.errorMessage}>
                    {userInfoErrors.country.message}
                  </p>
                )}
              </div>
            )}
          />
          <Controller
            name="phoneNumber"
            control={userInfoControl}
            defaultValue=""
            render={({ field }) => (
              <div className={s.phoneNumberField}>
                <MaskedInput
                  {...field}
                  className={cx(
                    textfieldStyles.textfield,
                    userInfoErrors.phoneNumber && textfieldStyles.errorState,
                  )}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  guide={false}
                  placeholder={t("phoneNumber") ?? ""}
                  mask={() => transformMask(`${phoneCode} ${masks[0]}`)}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value.startsWith(`${phoneCode} `)) {
                      value = `${phoneCode} ${value.slice(
                        phoneCode.length + 1,
                      )}`;
                    }
                    setValue("phoneNumber", value);
                  }}
                />
                {userInfoErrors.phoneNumber && (
                  <p className={textfieldStyles.errorMessage}>
                    {userInfoErrors.phoneNumber.message}
                  </p>
                )}
              </div>
            )}
          />

          <Button
            wide
            bold
            size="large"
            width="100%"
            disabled={!isUserInfoValid || isLoadingUserInfo}
            loading={isLoadingUserInfo}
          >
            {t("update")}
          </Button>
        </form>
      </div>
      <div className={s.content}>
        <SectionHeader title={t("changePassword")} action={<></>} />
        <form onSubmit={resetPasswordHandleSubmit(resetPassword)}>
          <Controller
            name="currentPassword"
            control={resetPasswordControl}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type={showCurrentPassword ? "text" : "password"}
                error={!!resetPasswordErrors.currentPassword}
                helperText={resetPasswordErrors.currentPassword?.message?.toString()}
                label="Current Password"
                placeholder="Current Password"
                iconRight={
                  <IconButton
                    type="flat"
                    icon={eyeIconCurrent}
                    onClick={toggleShowCurrentPassword}
                  />
                }
              />
            )}
          />
          <Controller
            name="newPassword"
            control={resetPasswordControl}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type={showNewPassword ? "text" : "password"}
                error={!!resetPasswordErrors.newPassword}
                helperText={resetPasswordErrors.newPassword?.message?.toString()}
                label="New Password"
                placeholder="New Password"
                iconRight={
                  <IconButton
                    type="flat"
                    icon={eyeIconNew}
                    onClick={toggleShowNewPassword}
                  />
                }
              />
            )}
          />
          <Controller
            name="newPasswordConfirmation"
            control={resetPasswordControl}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type={showNewPassword ? "text" : "password"}
                error={!!resetPasswordErrors.newPasswordConfirmation}
                helperText={resetPasswordErrors.newPasswordConfirmation?.message?.toString()}
                label="Confirm New Password"
                placeholder="Confirm New Password"
                iconRight={
                  <IconButton
                    type="flat"
                    icon={eyeIconNew}
                    onClick={toggleShowNewPassword}
                  />
                }
              />
            )}
          />
          <Button
            wide
            bold
            size="large"
            width="100%"
            disabled={!isResetPasswordValid || isLoading}
            loading={isLoading}
          >
            {t("changePassword")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Settings;
