/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.query<boolean, string>({
    query: (token) => {
      return {
        url: config.api.verifyToken.replace(":token", token),
      };
    },
    transformResponse: (response: string, meta: any) =>
      meta.response.status === 200,
  });
