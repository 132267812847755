/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type NonceResponse = {
  nonce: number;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.query<number, void>({
    query: () => {
      return {
        url: config.api.nonce,
      };
    },
    transformResponse: (response: NonceResponse) => response.nonce,
  });
