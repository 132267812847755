import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import IconButton from "components/IconButton/IconButton";
import { EyeCloseIcon, EyeOpenIcon } from "components/icons";
import TextField from "components/Textfield/Textfield";
import { resetPassword, selectIsLoggedIn } from "features/Auth/authSlice";
import BaseLayout from "layouts/BaseLayout/BaseLayout";

import { useChangePasswordSchema } from "./useChangePasswordSchema";

import s from "./ResetPassword.module.scss";

import config from "config/config";

type FormData = {
  password: string;
  passwordConfirmation: string;
};

const ChangePassword: React.FC = () => {
  const { t } = useTranslation("account");
  const [searchParams] = useSearchParams();
  const schema = useChangePasswordSchema();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const eyeIcon = showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />;

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    navigate(config.routes.login);
  }, [isLoggedIn, navigate]);

  const onSubmit = async (data: FormData) => {
    const isSuccessful = (
      await dispatch(
        resetPassword({
          email: searchParams.get("email") ?? "",
          token: searchParams.get("token") ?? "",
          password: data.password,
        }),
      )
    ).payload;
    if (isSuccessful) {
      setTimeout(() => {
        navigate(config.routes.login);
      }, 3000);
    }
  };

  return (
    <BaseLayout>
      <div className={s.root}>
        <div className={s.container}>
          <header
            className={s.header}
            style={{ textAlign: "center" }}
          >{t`resetPassword`}</header>
          <div className={s.content}>
            <div className={s.userPassArea}>
              <p className={s.message}>{t`changePasswordMessage`}</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword ? "text" : "password"}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      label={t("password")}
                      placeholder={t("password")}
                      iconRight={
                        <IconButton
                          type="flat"
                          icon={eyeIcon}
                          onClick={toggleShowPassword}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword ? "text" : "password"}
                      error={!!errors.passwordConfirmation}
                      helperText={errors.passwordConfirmation?.message}
                      placeholder={t("passwordConfirmation")}
                      label={t("passwordConfirmation")}
                      iconRight={
                        <IconButton
                          type="flat"
                          icon={eyeIcon}
                          onClick={toggleShowPassword}
                        />
                      }
                    />
                  )}
                />
                <div className={s.actionArea}>
                  <Button
                    wide
                    bold
                    size="large"
                    width={"100%"}
                    disabled={!isValid}
                  >
                    {t("reset")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ChangePassword;
