import cx from "classnames";

import { ILogo } from "./Logo.d";

import s from "./Logo.module.scss";

import { ReactComponent as LogoSVG } from "assets/svgs/crown-logo.svg";
import { ReactComponent as LogoWithBrandSVG } from "assets/svgs/crown-logo-large.svg";

const Logo: React.FC<ILogo> = ({ size, white, withBrand }) => {
  return (
    <div className={cx(s.root, white && s.white)}>
      {withBrand ? (
        <LogoWithBrandSVG
          style={{
            fill: "white",
            width: size,
            height: size,
          }}
        />
      ) : (
        <LogoSVG
          style={{
            fill: "white",
            width: size,
            height: size,
          }}
        />
      )}
    </div>
  );
};

export default Logo;
