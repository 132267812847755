import React, { forwardRef, ReactNode } from "react";
import cx from "classnames";

import s from "./Textfield.module.scss";

interface TextFieldProps {
  type?: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  iconRight?: ReactNode | null;
  placeholder?: string | null;
  helperText?: string;
  hideLabel?: boolean;
}

const TextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    type = "text",
    label,
    value,
    disabled = false,
    onChange,
    onBlur,
    error,
    placeholder,
    helperText,
    iconRight,
    hideLabel = true,
  },
  ref,
) => {
  return (
    <div style={{ margin: "1rem 0" }} className={s.root}>
      {!hideLabel && (
        <label style={{ display: "block", marginBottom: "0.5rem" }}>
          {label}
        </label>
      )}

      <div
        className={cx(
          s.textfield,
          disabled && s.readOnly,
          error && s.errorState,
        )}
      >
        <input
          ref={ref}
          disabled={disabled}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder ?? ""}
        />
        {iconRight}
      </div>
      {error && helperText && (
        <span className={s.errorMessage}>{helperText}</span>
      )}
    </div>
  );
};

export default forwardRef(TextField);
