/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { keysToCamelCase } from "shared/utils/keyCases";

import config from "config/config";

type AccountResponse = {
  first_name: string;
  last_name: string;
  country: string;
  phone_number: string;
  email: string;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.query<any, void>({
    query: () => {
      return {
        url: config.api.me,
      };
    },
    transformResponse: (response: AccountResponse) => {
      return keysToCamelCase(response);
    },
    transformErrorResponse: (response: any) => {
      return response.originalStatus;
    },
  });
