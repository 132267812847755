/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { UserDto } from "features/api/apiSchemas";
import { User } from "shared/types/common";

import config from "config/config";

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<boolean, User>({
    query: (params) => {
      const body: UserDto = {
        email: params.email,
        first_name: params.firstName,
        last_name: params.lastName,
        phone_number: params.phoneNumber,
        country: params.country,
      };
      return {
        url: config.api.me,
        method: "PUT",
        body,
      };
    },
    transformResponse: (response: string, meta: any) =>
      meta.response.status === 200,
  });
