import { useState } from "react";

import Button from "components/Button/Button";
import IconButton from "components/IconButton/IconButton";
import { CloseIcon } from "components/icons";
import useWindowSize from "shared/hooks/useWindowSize";

import { IDialog } from "./Dialog.d";

import s from "./Dialog.module.scss";

const Dialog: React.FC<IDialog> = ({
  children,
  title,
  isOpen,
  actionTitle,
  onAction,
  actionDisabled,
  onClose,
  hasAction = true,
  isDestructive = false,
  zIndex = 1000,
  maxWidth,
  isDissmisable = true,
}) => {
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  if (!isOpen) {
    return null;
  }

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    // Prevents the click event from bubbling up
    // to the backdrop and close the dialog
    e.stopPropagation();
  };

  const handleAction = async () => {
    setIsLoading(true);
    onAction && (await onAction());
    setIsLoading(false);
  };

  return (
    <div className={s.backDrop} onClick={onClose} style={{ zIndex }}>
      <div
        className={s.dialog}
        onClick={stopPropagation}
        style={!isMobile ? { maxWidth } : { maxWidth: "100%" }}
      >
        <div className={s.header}>
          {isDissmisable && (
            <IconButton
              type="bare"
              size="small"
              onClick={onClose}
              icon={<CloseIcon />}
            />
          )}
          <h1 className={s.title}>{title}</h1>
        </div>

        <div className={s.content}>{children}</div>
        {hasAction && (
          <div className={s.actions}>
            {isMobile ? (
              actionTitle && (
                <Button
                  disabled={actionDisabled}
                  size="large"
                  width="100%"
                  wide
                  onClick={handleAction}
                  destructive={isDestructive}
                >
                  {actionTitle}
                </Button>
              )
            ) : (
              <>
                {isDissmisable && (
                  <Button size="medium" secondary={true} onClick={onClose}>
                    Cancel
                  </Button>
                )}
                {actionTitle && (
                  <Button
                    disabled={actionDisabled || isLoading}
                    size="medium"
                    onClick={handleAction}
                    destructive={isDestructive}
                    loading={isLoading}
                  >
                    {actionTitle}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dialog;
