import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "components/Dialog/Dialog";

import EulaContent from "./EulaContent";

import s from "./Eula.module.scss";

export type Props = {
  isOpen: boolean;
  isDismissable?: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const EulaModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onAccept,
  isDismissable = true,
}) => {
  const { t } = useTranslation("account");

  const handleAcceptEula = useCallback(() => {
    onAccept();
    onClose();
  }, [onAccept, onClose]);

  return (
    <Dialog
      title={t("eula")}
      isOpen={isOpen}
      actionTitle={"Accept"}
      onAction={handleAcceptEula}
      onClose={onClose}
      zIndex={1001}
      maxWidth={800}
      isDissmisable={isDismissable}
    >
      <div className={s.root}>
        <EulaContent />
      </div>
    </Dialog>
  );
};
export default EulaModal;
