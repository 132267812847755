import cx from "classnames";

import { IncomingIcon, OutgoingIcon } from "components/icons";

import s from "./TransactionsGrid.module.scss";

type ITransactionCard = {
  isIncoming: boolean;
};

const TransactionType = ({ isIncoming }: ITransactionCard) => {
  return (
    <div
      className={cx({
        [s.iconContainer]: true,
        [s.incomingTransaction]: isIncoming,
        [s.outgoingTransaction]: !isIncoming,
      })}
    >
      {isIncoming ? <IncomingIcon /> : <OutgoingIcon />}
    </div>
  );
};

export default TransactionType;
