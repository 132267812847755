/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type BalanceResponse = {
  balance: string;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.query<number, string>({
    query: (address) => {
      return {
        url: config.api.balance.replace(":id", address),
      };
    },
    transformResponse: (response: BalanceResponse) => {
      return parseFloat(response.balance);
    },
  });
