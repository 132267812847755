import { useTranslation } from "react-i18next";
import { COUNTRY_OPTIONS } from "const/countries";
import * as yup from "yup";

const phoneSchema = yup
  .string()
  .required("Phone number is required")
  .test("matches-mask", "Phone number is not valid", (value, context) => {
    const selectedCountry = COUNTRY_OPTIONS.find(
      (option) => option.value === context.parent.country,
    );

    if (!selectedCountry) return false;

    const phoneCode = selectedCountry?.phoneCode || "";

    const masks = Array.isArray(selectedCountry.phoneMask)
      ? selectedCountry.phoneMask
      : [selectedCountry.phoneMask];

    return masks.some((mask) => {
      const expectedDigits =
        (mask.match(/#/g) || []).length + phoneCode.length + 1;
      const actualDigits =
        (value.match(/\d/g) || []).length +
        (value.match(/\+/g) || []).length +
        (value.match(/ /g) || []).length;
      return expectedDigits === actualDigits;
    });
  });

export const useRegisterSchema = () => {
  const { t } = useTranslation("account");
  const schema = yup.object().shape({
    firstName: yup.string().required(t("firstName.required") ?? ""),
    lastName: yup.string().required(t("lastName.required") ?? ""),
    country: yup.string().required(t("country.required") ?? ""),
    phoneNumber: phoneSchema,
    email: yup
      .string()
      .required(t("email.required") ?? "")
      .email("Please provide a valid email"),
    password: yup
      .string()
      .required(t("password.required") ?? "")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/,
        t("password.invalid") ?? "",
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], t("password.match") ?? ""),
  });
  return schema;
};
