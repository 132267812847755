import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { bnToBn } from "@polkadot/util";
import * as Yup from "yup";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Dialog from "components/Dialog/Dialog";
import { WarningIcon } from "components/icons";
import TextField from "components/Textfield/Textfield";
import {
  selectBalance,
  selectNonce,
  transfer,
} from "features/Wallet/walletSlice";
import { CSOV_CONVERSION } from "shared/utils/csov";

import s from "./TransferModal.module.scss";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = Yup.object().shape({
  recipient: Yup.string().required("Address is required"),
  amount: Yup.string().required("Amount is required"),
});

type FormData = {
  recipient: string;
  amount: string;
};

const TransferModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const balance = useAppSelector(selectBalance) ?? 0;
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const nonce = useAppSelector(selectNonce);
  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: FormData) => {
    let amount: number = parseInt(data.amount);
    // FIXME: This check can prevent legitimate corner case transfers

    amount = isNaN(amount) ? 0 : amount;
    const amountAsString = bnToBn(amount).mul(CSOV_CONVERSION).toString();

    if (balance >= amount) {
      await dispatch(
        transfer({
          recipient: data.recipient,
          amount: amountAsString,
          nonce: nonce,
        }),
      );
    } else {
      toast.error(t("noFunds"));
    }
    handleClose();
  };

  return (
    <Dialog
      title={t("transfer")}
      isOpen={isOpen}
      actionTitle={t("transfer") as string}
      onAction={handleSubmit(onSubmit)}
      onClose={handleClose}
      zIndex={1001}
    >
      <div className={s.root}>
        <form>
          <Controller
            name="recipient"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Address"
                placeholder="Address"
                error={Boolean(errors.recipient)}
                helperText={errors.recipient?.message}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Amount"
                placeholder="Amount"
                error={Boolean(errors.amount)}
                helperText={errors.amount?.message}
              />
            )}
          />
        </form>
        <p className={s.footer}>
          <WarningIcon />
          {t("transfer.footer")}
        </p>
      </div>
    </Dialog>
  );
};
export default TransferModal;
