import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";
import { COUNTRY_OPTIONS } from "const/countries";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";
import IconButton from "components/IconButton/IconButton";
import { EyeCloseIcon, EyeOpenIcon } from "components/icons";
import TextField from "components/Textfield/Textfield";
import textfieldStyles from "components/Textfield/Textfield.module.scss";
import Textlink from "components/Textlink/Textlink";
import { register, selectIsLoggedIn } from "features/Auth/authSlice";
import BaseLayout from "layouts/BaseLayout/BaseLayout";
import useDisclosure from "shared/hooks/useDisclosure";

import EulaModal from "./EulaDialog/EulaModal";
import { customStyles } from "./styles";
import { useRegisterSchema } from "./useRegisterSchema";

import s from "./Register.module.scss";

import config from "config/config";

const WIDTH = 268;

type FormInputs = {
  firstName: string;
  lastName: string;
  country: string;
  phoneNumber: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

const Register: React.FC = () => {
  const { t } = useTranslation("account");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isTermAccepted, setIsTermAccepted] = useState(false);
  const dispatch = useAppDispatch();

  const {
    isOpen: isEulaModalOpen,
    onOpen: onEulaModalOpen,
    onClose: onEulaModalClose,
  } = useDisclosure();

  const schema = useRegisterSchema();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const countryValue = watch("country");
  const country = useMemo(
    () => COUNTRY_OPTIONS.find((c) => c.value === countryValue),
    [countryValue],
  );
  const phoneCode = useMemo(() => country?.phoneCode ?? "", [country]);
  const masks = useMemo(
    () =>
      country
        ? Array.isArray(country.phoneMask)
          ? country.phoneMask
          : [country.phoneMask]
        : [],
    [country],
  );

  const transformMask = (mask: string) =>
    mask.split("").map((char) => (char === "#" ? /\d/ : char));

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    navigate(config.routes.home);
  }, [isLoggedIn, navigate]);

  const eyeIcon = showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />;

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsLoading(true);
    const isSuccessful = (await dispatch(register(data))).payload;
    setIsLoading(false);
    if (isSuccessful) {
      toast.success(t("register.success"));
      setTimeout(() => {
        navigate(config.routes.login);
      }, 3000);
    }
  };

  return (
    <BaseLayout>
      <EulaModal
        isOpen={isEulaModalOpen}
        onClose={onEulaModalClose}
        onAccept={() => setIsTermAccepted(true)}
      />
      <div className={s.root}>
        <div className={s.container}>
          <header className={s.header}>{t`createAnAccount`}</header>
          <div className={s.content}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: WIDTH }}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    label={t("firstName")}
                    placeholder={t("firstName")}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    label={t("lastName")}
                    placeholder={t("lastName")}
                  />
                )}
              />
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <div>
                    <Select
                      {...field}
                      value={COUNTRY_OPTIONS.find(
                        (option) => option.value === value,
                      )}
                      classNames={{
                        control: () => errors.country && s.errorState,
                      }}
                      options={COUNTRY_OPTIONS}
                      isSearchable={true}
                      styles={customStyles}
                      onChange={(option) => {
                        onChange(option?.value);
                        setValue("phoneNumber", option?.phoneCode ?? "");
                      }}
                      onBlur={field.onBlur}
                    />
                    {errors.country && (
                      <p className={s.errorMessage}>{errors.country.message}</p>
                    )}
                  </div>
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className={s.phoneNumberField}>
                    <MaskedInput
                      {...field}
                      className={cx(
                        textfieldStyles.textfield,
                        errors.phoneNumber && textfieldStyles.errorState,
                      )}
                      guide={false}
                      placeholder={t("phoneNumber") ?? ""}
                      mask={() => transformMask(`${phoneCode} ${masks[0]}`)}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (!value.startsWith(`${phoneCode} `)) {
                          value = `${phoneCode} ${value.slice(
                            phoneCode.length + 1,
                          )}`;
                        }
                        setValue("phoneNumber", value);
                      }}
                    />
                    {errors.phoneNumber && (
                      <p className={textfieldStyles.errorMessage}>
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    label={t("email")}
                    placeholder={t("email")}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    label={t("password")}
                    placeholder={t("password")}
                    iconRight={
                      <IconButton
                        type="flat"
                        icon={eyeIcon}
                        onClick={toggleShowPassword}
                      />
                    }
                  />
                )}
              />
              <Controller
                name="passwordConfirmation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    error={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation?.message}
                    placeholder={t("passwordConfirmation")}
                    label={t("passwordConfirmation")}
                    iconRight={
                      <IconButton
                        type="flat"
                        icon={eyeIcon}
                        onClick={toggleShowPassword}
                      />
                    }
                  />
                )}
              />
              <div className={s.checkBoxArea}>
                <Checkbox
                  checked={isTermAccepted}
                  onChange={() => setIsTermAccepted((prev) => !prev)}
                  label={
                    <Trans
                      i18nKey="account:termsAndConditions"
                      components={[
                        <Textlink
                          key={"terms"}
                          text={t("terms")}
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            onEulaModalOpen();
                          }}
                        />,
                      ]}
                    />
                  }
                />
              </div>
              <div className={s.actionArea}>
                <Button
                  wide
                  bold
                  size="large"
                  loading={isLoading}
                  disabled={!isTermAccepted || !isValid || isLoading}
                >
                  {t("register")}
                </Button>
              </div>
            </form>
            <div className={s.backArea}>
              <span>{t("alreadyHaveAnAccount")}</span>
              <Textlink to={config.routes.login} text={t("login")} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Register;
