import { api } from "features/api";

import getAccount from "./getAccount";
import getAddress from "./getAddress";
import getBalance from "./getBalance";
import getNonce from "./getNonce";
import getTransactions from "./getTransactions";
import transfer from "./transfer";

export const walletApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getAccount: getAccount(build),
      getTransactions: getTransactions(build),
      getBalance: getBalance(build),
      getAddress: getAddress(build),
      transfer: transfer(build),
      nonce: getNonce(build),
    };
  },
});
