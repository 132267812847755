import defaultAvatarPerson from "assets/svgs/default-avatar-person.svg";

const serverURLs = () => {
  return {
    baseUrl: process.env.REACT_APP_SERVER ?? "http://localhost:8080/",
  };
};

const config = {
  appPrefix: "csov-", // prefix for local storage
  defaultUserImageUrl: defaultAvatarPerson,
  userImageById: "api/images/:id",
  hashPasswordRounds: 50,
  routes: {
    home: "/",
    register: "/register",
    login: "/login",
    settings: "/settings",
    resetPassword: "/reset-password",
    changePassword: "/change-password",
    verifiedEmail: "/successfully-verified-email",
    help: "https://help.crownsterling.io/knowledge",
    verify: "verify/:token",
  },
  api: {
    ...serverURLs(),

    registerUsersBasic: "register",
    transactions: "users/:id/transactions",
    me: "me",
    changePassword: "change_password",
    resetPasswordInit: "init-password-reset",
    resetPassword: "reset_password",
    balance: "users/:id/balance",
    address: "me/address",
    verifyToken: "verify/:token",
    acceptEula: "me/accept-eula",
    session: "session",
    nonce: "me/nonce",
  },
  storageKeys: {
    token: "token",
  },
  sessionToken: {
    sessionExpired: "Session expired.",
  },
};

export default config;
