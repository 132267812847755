import cx from "classnames";

import { ISpinner } from "./Spinner.d";

import s from "./Spinner.module.scss";

const Spinner: React.FC<ISpinner> = ({
  size = "small",
  message,
  gap = 0,
  subMessage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap,
      }}
    >
      <div className={cx(s.root, s[size])}></div>
      <div>
        {message && <div className={s.message}>{message}</div>}
        {subMessage && <div className={s.message}>{subMessage}</div>}
      </div>
    </div>
  );
};

export default Spinner;
