export const formatTimestamp = (dateString: string) => {
  const now = new Date();
  const timestamp = Date.parse(dateString);
  const date = new Date(timestamp);
  const elapsedTime = now.getTime() - date.getTime();

  const msInMinute = 60 * 1000;
  const msInHour = 60 * msInMinute;
  const msInDay = 24 * msInHour;
  const msInWeek = 7 * msInDay;
  const msInMonth = 30 * msInDay;

  if (elapsedTime < msInDay) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  } else if (elapsedTime < msInWeek) {
    const days = Math.floor(elapsedTime / msInDay);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (elapsedTime < msInMonth) {
    const weeks = Math.floor(elapsedTime / msInWeek);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else {
    const months = Math.floor(elapsedTime / msInMonth);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
};

export const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();

  let formattedDate: string;

  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    formattedDate = "Today";
  } else {
    formattedDate = date.toDateString();
  }

  const formattedTimeParts: string[] = date.toTimeString().split(":");
  formattedTimeParts.pop(); // Remove seconds
  const formattedTime: string = formattedTimeParts.join(":");

  return `${formattedDate}, at ${formattedTime}`;
};
