import { Outlet } from "react-router-dom";

import Navbar from "components/Navbar/Navbar";

import s from "./MessengerLayout.module.scss";

const MessengerLayout: React.FC = () => {
  return (
    <div className={s.root}>
      <Navbar />
      <div className={s.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default MessengerLayout;
