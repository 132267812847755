import { useCallback, useEffect, useState } from "react";

type WindowSize = {
  width: number;
  height: number;
};

type UseWindowSize = {
  windowSize: WindowSize;
  isMobile: boolean;
};

function useWindowSize(): UseWindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  // Set size at the first client-side load
  useEffect(() => {
    handleSize();
    window.addEventListener("resize", handleSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSize]);

  return { windowSize, isMobile: windowSize.width < 767 };
}

export default useWindowSize;
