import { BN, bnToBn } from "@polkadot/util";

export const CSOV_CONVERSION = bnToBn(1000000000000);

export const stripValue = (balance: number | BN | null) => {
  if (balance) {
    const bnBalance = bnToBn(balance); // Note: BigNumber instantiation from a string to prevent precision loss const divisor = new BN('1000000000000');

    // formatting the balance
    const formattedString =
      bnBalance.div(CSOV_CONVERSION).toString() +
      "." +
      bnBalance
        .mod(CSOV_CONVERSION)
        .div(bnToBn("10000000000"))
        .toString()
        .padStart(2, "0");

    return parseFloat(formattedString);
  }
  return 0;
};
export const convertBalanceToBN = (balance: number) => {
  // Formats to maintain decimals
  const formattedBalance = bnToBn(balance * 100)
    .mul(CSOV_CONVERSION)
    .div(bnToBn(100));
  return formattedBalance;
};
