import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const useChangePasswordSchema = () => {
  const { t } = useTranslation("account");
  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("password.required") ?? "")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~`[\]{}|:;"'<>,./?])(?!.*\s).{12,}$/,
        t("password.invalid") ?? "",
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], t("password.match") ?? ""),
  });

  return schema;
};
