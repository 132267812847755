import { api } from "features/api";

import postSession from "./postSession";

// Todo add logout
export const sessionApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      postSession: postSession(build),
    };
  },
});

export const { usePostSessionMutation } = sessionApi;
