/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { Transaction } from "shared/types/common";
import { keysToCamelCase } from "shared/utils/keyCases";

import config from "config/config";

type TransactionResponse = TransactionDTO[];

type TransactionDTO = {
  amount: string;
  block_number: number;
  to: string;
  from: string;
  block_time: number;
  nonce: number;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.query<Transaction[], string>({
    query: (id) => {
      return {
        url: config.api.transactions.replace(":id", id),
      };
    },
    keepUnusedDataFor: 0,
    transformResponse: (response: TransactionResponse) => {
      const results = response.map((transfer: TransactionDTO) => {
        return keysToCamelCase(transfer);
      });

      return results;
    },
  });
