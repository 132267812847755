import cx from "classnames";

import { IIconButton } from "./IconButton.d";

import s from "./IconButton.module.scss";

const IconButton: React.FC<IIconButton> = ({
  icon,
  size = "small",
  type = "primary",
  disabled,
  selected,
  onClick,
}) => {
  return (
    <button
      type="button"
      className={cx(s.root, s[size], s[type], selected && s.selected)}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </button>
  );
};

export default IconButton;
