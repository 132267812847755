/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation({
    query: () => {
      return {
        url: config.api.acceptEula,
        method: "PUT",
      };
    },
    transformResponse: (response: string, meta: any) => {
      return meta.response.status === 200;
    },
  });
