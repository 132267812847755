/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, any>({
    query: (params) => {
      const body: any = {
        recipient: params.recipient,
        amount: params.amount,
        nonce: params.nonce,
      };
      return {
        url: config.api.balance.replace(":id", params.address),
        method: "PUT",
        body,
      };
    },
  });
