import { useEffect, useState } from "react";

import config from "config/config";

export const useLocalStorageState = (key: string, initialValue: unknown) => {
  const localStorageKey = config.appPrefix + key;

  const [storedValue, setStoredValue] = useState(
    getLocalStorageValue(localStorageKey) || initialValue,
  );

  // Keep the value in local storage in synk with the state
  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(storedValue));
    return () => {
      window.localStorage.setItem(localStorageKey, JSON.stringify(storedValue));
    };
  }, [storedValue, localStorageKey]);

  return [storedValue, setStoredValue];
};

// Get the value from local storage and parse it
export const getLocalStorageValue = (localStorageKey: string) => {
  const value = window.localStorage.getItem(localStorageKey);

  if (!value) {
    return;
  }
  if (value !== "undefined") {
    return;
  }

  return JSON.parse(value);
};
