import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { persistor } from "app/store";
import { logout, selectIsLoggedIn } from "features/Auth/authSlice";
import ChangePassword from "features/ResetPassword/ChangePassword";
import Settings from "features/Settings/Settings";
import BaseLayout from "layouts/BaseLayout/BaseLayout";
import MessengerLayout from "layouts/MessengerLayout/MessengerLayout";
import HomePage from "pages/HomePage";
import LoginPage from "pages/LoginPage";
import RegisterPage from "pages/RegisterPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import VerifiedEmailPage from "pages/VerifiedEmailPage";
import { getTokenFromSessionStorage } from "shared/utils/storage";

import "react-toastify/dist/ReactToastify.css";

import config from "config/config";

const { routes } = config;

type IPrivareRoute = {
  children: JSX.Element;
};

const PrivateRoute: React.FC<IPrivareRoute> = ({ children }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();

  if (!isLoggedIn) {
    // Redirect to Login page if user is not logged in
    return <Navigate to={config.routes.login} />;
  }

  // Wait for password to be stored in sessionStorage after login
  setTimeout(() => {
    // Get password from sessionStorage
    const token = getTokenFromSessionStorage();
    // If password is not stored in sessionStorage
    if (!token) {
      // Logout user
      persistor.purge();
      dispatch(logout());
    }
  }, 2000);

  // Render requested page
  return children;
};

export default function RouteComponent() {
  return (
    <BaseLayout>
      <ToastContainer />
      <Routes>
        <Route path={routes.verifiedEmail} element={<VerifiedEmailPage />} />
        <Route path={routes.verify} element={<VerifiedEmailPage />} />
        <Route path={routes.register} element={<RegisterPage />} />
        <Route path={routes.login} element={<LoginPage />} />
        <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
        <Route path={routes.changePassword} element={<ChangePassword />} />
        <Route
          element={
            <PrivateRoute>
              <MessengerLayout />
            </PrivateRoute>
          }
        >
          <Route path={routes.home} element={<HomePage />} />
          <Route path={routes.settings} element={<Settings />} />
          <Route path={"*"} element={<>not found</>} />
        </Route>
      </Routes>
    </BaseLayout>
  );
}
