import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import TextField from "components/Textfield/Textfield";
import { resetPasswordInit, selectIsLoggedIn } from "features/Auth/authSlice";
import BaseLayout from "layouts/BaseLayout/BaseLayout";

import { useResetPaswordSchema } from "./useResetPasswordSchema";

import s from "./ResetPassword.module.scss";

import config from "config/config";

type FormData = {
  email: string;
};

const ResetPassword: React.FC = () => {
  const { t } = useTranslation("account");
  const [isLoading, setIsLoading] = useState(false);
  const schema = useResetPaswordSchema();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    navigate(config.routes.login);
  }, [isLoggedIn, navigate]);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    const isSuccessful = (await dispatch(resetPasswordInit(data.email)))
      .payload;
    setIsLoading(false);
    if (isSuccessful) {
      setTimeout(() => {
        navigate(config.routes.login);
      }, 3000);
    }
  };

  return (
    <BaseLayout>
      <div className={s.root}>
        <div className={s.container}>
          <header className={s.header}>{t`resetPassword`}</header>
          <div className={s.content}>
            <div className={s.userPassArea}>
              <p className={s.message}>{t`resetPasswordMessage`}</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("email")}
                      placeholder={t("email")}
                      error={!!errors.email}
                      helperText={errors?.email?.message?.toString()}
                    />
                  )}
                />
                <div className={s.actionArea}>
                  <Button
                    wide
                    bold
                    size="large"
                    width={"100%"}
                    loading={isLoading}
                    disabled={isLoading || !isValid}
                  >
                    {t("reset")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ResetPassword;
