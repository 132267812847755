import cx from "classnames";

import { numberFormat } from "shared/utils/numbers";

import { IBadge } from "./Badge.d";

import s from "./Badge.module.scss";

const Badge: React.FC<IBadge> = ({ value, square }) => {
  return (
    <span className={cx(s.root, square && s.square)}>
      {numberFormat.format(value)}
    </span>
  );
};

export default Badge;
