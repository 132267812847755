import Dialog from "components/Dialog/Dialog";
import IconButton from "components/IconButton/IconButton";
import { CopyIcon } from "components/icons";
import { Transaction } from "shared/types/common";
import { stripValue } from "shared/utils/csov";

import s from "./TransactionDetailsModal.module.scss";

export type Props = {
  transaction:
    | (Transaction & {
        isIncoming: boolean;
        id: string;
      })
    | undefined;
  isOpen: boolean;
  isDismissable?: boolean;
  onClose: () => void;
};

const TransactionDetailsModal: React.FC<Props> = ({
  transaction,
  isOpen,
  onClose,
}) => {
  if (!transaction) return <></>;

  const amountToDisplay = stripValue(transaction.amount);
  const copyToClipboard = (address: string) => {
    navigator.clipboard.writeText(address ?? "");
  };
  return (
    <Dialog
      title={"Transaction Details"}
      isOpen={isOpen}
      hasAction={false}
      onClose={onClose}
      zIndex={1001}
    >
      <div className={s.root}>
        <div className={s.details}>
          <div className={s.row}>
            <span className={s.name}>Type</span>
            <span>{transaction.isIncoming ? "Received" : "Sent"}</span>
          </div>
          <div className={s.row}>
            <span className={s.name}>Amount</span>
            <span className={transaction.isIncoming ? s.received : s.sent}>
              {transaction.isIncoming ? "+" : "-"} {amountToDisplay}
            </span>
          </div>
          <div className={s.row}>
            <span className={s.name}>Block Time</span>
            <span>{transaction.blockTime}</span>
          </div>
          <div className={s.row}>
            <span className={s.name}>Block Number</span>
            <span>{transaction.blockNumber}</span>
          </div>
          <div className={s.addressContainer}>
            <span className={s.name}>To</span>
            <div className={s.address}>
              <span className={s.ellipsis}>{transaction.to}</span>
              <IconButton
                size="small"
                onClick={() => copyToClipboard(transaction.to)}
                icon={<CopyIcon />}
              />
            </div>
          </div>
          <div className={s.addressContainer}>
            <span className={s.name}>From</span>
            <div className={s.address}>
              <span className={s.ellipsis}>{transaction.from}</span>
              <IconButton
                size="small"
                onClick={() => copyToClipboard(transaction.from)}
                icon={<CopyIcon />}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TransactionDetailsModal;
