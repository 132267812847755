/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type changePasswordArgs = {
  newPassword: string;
  oldPassword: string;
};
type changePassword = {
  new: string;
  old: string;
};

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<boolean, changePasswordArgs>({
    query: (args) => {
      const body: changePassword = {
        new: args.newPassword,
        old: args.oldPassword,
      };

      return {
        url: config.api.changePassword,
        method: "PUT",
        body,
      };
    },
    transformResponse: (response: string, meta: any) =>
      meta.response.status === 200,
  });
