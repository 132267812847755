/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type SessionArgs = {
  email: string;
  password: string;
};

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<string, SessionArgs>({
    query: (args) => {
      const body: SessionArgs = {
        email: args.email,
        password: args.password,
      };
      return {
        url: config.api.session,
        method: "POST",
        body,
      };
    },
  });
