/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type AddressResponse = {
  account_address: string;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.query<string, void>({
    query: () => {
      return {
        url: config.api.address,
      };
    },
    transformResponse: (response: AddressResponse) => {
      return response.account_address;
    },
  });
