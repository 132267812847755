import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridEventListener,
  GridRowParams,
  GridToolbar,
} from "@mui/x-data-grid";

import { useAppSelector } from "app/hooks";
import { selectAddress, selectTransactions } from "features/Wallet/walletSlice";
import useDisclosure from "shared/hooks/useDisclosure";

import TransactionDetailsModal from "../TransactionDetailsModal/TransactionDetailsModal";

import { columns } from "./GridConfig";

const TransactionsGrid = () => {
  const transactions = useAppSelector(selectTransactions);
  const address = useAppSelector(selectAddress);
  const { t } = useTranslation();
  const [selectedTransaction, setSelectedTransaction] = useState();

  const {
    isOpen: isTransactionDetailsModalOpen,
    onOpen: onTransactionDetailsModelOpen,
    onClose: onTransactionDetailsModalClose,
  } = useDisclosure();

  const enhancedTransactions = useMemo(() => {
    return transactions.map((transaction, index) => {
      return {
        id: index,
        ...transaction,
        isIncoming: transaction.to === address,
      };
    });
  }, [transactions, address]);

  const handleTransactionClick: GridEventListener<"rowClick"> = async (
    params: GridRowParams,
  ) => {
    setSelectedTransaction(params.row);
    onTransactionDetailsModelOpen();
  };
  return (
    <>
      <TransactionDetailsModal
        isOpen={isTransactionDetailsModalOpen}
        onClose={onTransactionDetailsModalClose}
        transaction={selectedTransaction}
      />
      <div style={{ flex: 1, width: "100%", paddingBottom: "var(--xl-gap)" }}>
        <DataGrid
          disableRowSelectionOnClick
          rows={enhancedTransactions}
          rowCount={enhancedTransactions.length}
          onRowClick={handleTransactionClick}
          localeText={{
            noRowsLabel: t("noTransactions") ?? "No transactions",
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              ...enhancedTransactions,
              sortModel: [
                {
                  field: "timestamp",
                  sort: "desc",
                },
              ],
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          columns={columns}
          sx={{ background: "transparent", border: "none" }}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
    </>
  );
};

export default TransactionsGrid;
