import { api } from "features/api";

import postRegisterUsersBasic from "./postRegisterUsersBasic";

export const registerApi = api.injectEndpoints({
  endpoints: (build) => ({
    postRegisterUsersBasic: postRegisterUsersBasic(build),
  }),
});

export const { usePostRegisterUsersBasicMutation } = registerApi;
