import cx from "classnames";

import { ICheckbox } from "./Checkbox.d";

import s from "./Checkbox.module.scss";

const Checkbox: React.FC<ICheckbox> = ({
  type = "checkbox",
  onChange,
  label,
  checked,
  round,
  disabled,
}) => {
  return (
    <div className={s.root}>
      <div className={cx(s.box, round && s.round)}>
        <input
          disabled={disabled}
          checked={checked}
          type={type}
          onChange={onChange}
        />
      </div>
      <span>{label}</span>
    </div>
  );
};

export default Checkbox;
