/* eslint-disable @typescript-eslint/no-explicit-any */
export const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    padding: "0 var(--xs-gap)",
    color: "var(--primary-text)",
    borderRadius: "var(--border-radius)",
    borderColor: "var(--border-color)",
    backgroundColor: "var(--alternate-background)",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "var(--alternate-background)",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? "black" : provided.color,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "var(--faded-text)",
  }),
};
