import Badge from "components/Badge/Badge";

import { ISectionHeader } from "./SectionHeader.d";

import s from "./SectionHeader.module.scss";

const SectionHeader: React.FC<ISectionHeader> = ({
  title,
  count,
  action,
  children,
}) => {
  return (
    <div className={s.root}>
      <div className={s.titleArea}>
        {title}
        {count !== undefined && <Badge value={count} square />}
      </div>
      {children}
      {action}
    </div>
  );
};

export default SectionHeader;
