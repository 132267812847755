import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const useResetPaswordSchema = () => {
  const { t } = useTranslation("account");
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t("email.invalid") ?? "")
      .required(t("email.required") ?? ""),
  });

  return schema;
};
