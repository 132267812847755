import config from "config/config";

// Save data to sessionStorage
export const saveToSessionStorage = (key: string, data: unknown) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

// Get data from sessionStorage
export const getFromSessionStorage = (key: string) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

// Remove data from sessionStorage
export const removeFromSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

// Clear sessionStorage
export const clearSessionStorage = () => {
  sessionStorage.clear();
};

// Save password to session storage
export const saveTokenToSessionStorage = (token: string) => {
  saveToSessionStorage(config.storageKeys.token, token);
};

// Get password from session storage
export const getTokenFromSessionStorage = () => {
  return getFromSessionStorage(config.storageKeys.token);
};
