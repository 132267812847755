import { cloneElement } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import { INavButton } from "./NavButton.d";

import s from "./NavButton.module.scss";

const NavButton: React.FC<INavButton> = ({
  to,
  icon,
  disabled,
  text,
  external = false,
}) => {
  const navlinkClasses = ({ isActive }: { isActive: boolean }) => {
    return cx(s.root, disabled && s.disabled, isActive && s.selected);
  };

  return (
    <NavLink
      to={to}
      className={navlinkClasses}
      target={external ? "_blank" : "_self"}
    >
      {cloneElement(icon)}
      <span className={s.text}>{text}</span>
    </NavLink>
  );
};

export default NavButton;
