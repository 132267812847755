import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { selectCurrentUserAddress } from "features/Auth/authSlice";

import s from "./DepositModal.module.scss";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DepositModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const address = useAppSelector(selectCurrentUserAddress);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(address ?? "");
    onClose();
  };

  return (
    <Dialog
      title={t("deposit.title")}
      isOpen={isOpen}
      isDestructive={true}
      onAction={() => null}
      onClose={onClose}
      zIndex={1001}
      hasAction={false}
    >
      <div className={s.root}>
        <div className={s.addressContainer}>
          <div className={s.ellipsis}>{address}</div>
        </div>
        <Button size="medium" onClick={copyToClipboard}>
          Copy to Clipboard
        </Button>
        <p className={s.footerNote}>{t("deposit.footer")}</p>
      </div>
    </Dialog>
  );
};
export default DepositModal;
