import cx from "classnames";

import Spinner from "components/Spinner/Spinner";

import { IButton } from "./Button.d";

import s from "./Button.module.scss";

const Button: React.FC<IButton> = ({
  children,
  size = "small",
  wide,
  icon,
  bold,
  secondary,
  disabled,
  onClick,
  width,
  destructive,
  loading = false,
}) => {
  return (
    <button
      style={{ width }}
      className={cx(
        s.root,
        s[size],
        wide && s.wide,
        bold && s.bold,
        secondary && s.secondary,
        destructive && s.destructive,
        secondary && destructive && s.secondaryDestructive,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {!loading ? (
        <>
          {icon && icon}
          <span className={s.text}>{children}</span>
        </>
      ) : (
        <Spinner />
      )}
    </button>
  );
};

export default Button;
