import { toast } from "react-toastify";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { PURGE } from "redux-persist";

import { RootState } from "app/store";
import { walletApi } from "features/api/services/wallet";
import { logout, selectCurrentUserAddress } from "features/Auth/authSlice";
import { ApiError, Transaction, Transfer } from "shared/types/common";

import i18n from "../../i18n";

import config from "config/config";

type WalletState = {
  transactions: Transaction[];
  balance: number | null;
  isLoadingBlockchainData: boolean;
  nonce: number | null;
};

const initialState: WalletState = {
  transactions: [],
  balance: null,
  isLoadingBlockchainData: false,
  nonce: null,
};

export const getAllUserDetails = createAsyncThunk(
  "wallet/getUserWallet",
  async (_, { getState, dispatch }) => {
    try {
      const rootState = getState() as RootState;

      const address = selectCurrentUserAddress(rootState);
      if (!address) {
        throw new Error("No current user address");
      }
      const transactions = await dispatch(
        walletApi.endpoints.getTransactions.initiate(address, {
          forceRefetch: true,
        }),
      ).unwrap();

      const balance = await dispatch(
        walletApi.endpoints.getBalance.initiate(address, {
          forceRefetch: true,
        }),
      ).unwrap();

      const nonce = await dispatch(
        walletApi.endpoints.nonce.initiate(_, { forceRefetch: true }),
      ).unwrap();
      return { transactions, balance, nonce };
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.data === config.sessionToken.sessionExpired) {
        dispatch(logout());
        toast.error(apiError.data, {
          toastId: "error",
        });
      }
    }
  },
);

export const transfer = createAsyncThunk(
  "transfer",
  async (params: Transfer, { getState, dispatch }) => {
    try {
      const rootState = getState() as RootState;

      const address = selectCurrentUserAddress(rootState);
      const results = await dispatch(
        walletApi.endpoints.transfer.initiate({ address: address, ...params }),
      ).unwrap();
      dispatch(incrementNonce());
      await dispatch(getAllUserDetails());
      toast.success(i18n.t("successfullTransfer"));
      return results;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.data === config.sessionToken.sessionExpired) {
        dispatch(logout());
        toast.error(apiError.data, {
          toastId: "error",
        });
      }
    }
  },
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    incrementNonce: (state) => {
      const current = state.nonce;
      const updatedNonce = current ? current + 1 : 1;
      state.nonce = updatedNonce;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(getAllUserDetails.pending, (state) => {
      state.isLoadingBlockchainData = true;
    });

    builder.addCase(getAllUserDetails.fulfilled, (state, action) => {
      const { transactions, balance, nonce } = action.payload ?? {};
      state.transactions = transactions ?? [];
      state.balance = balance ?? null;
      state.nonce = nonce ?? null;
      state.isLoadingBlockchainData = false;
    });

    builder.addMatcher(
      walletApi.endpoints.getBalance.matchRejected,
      (_, { payload }) => {
        const data = payload?.data;
        if (data === config.sessionToken.sessionExpired) {
          toast.error(data);
          // Logout user if session expired
          return;
        }

        const errorMessage = data || t("unknownError");
        toast.error(errorMessage);
      },
    );
  },
});

export const { incrementNonce } = walletSlice.actions;

export const selectIsLoading = (state: RootState) => {
  return state.wallet.isLoadingBlockchainData;
};
export const selectNonce = (state: RootState) => {
  return state.wallet.nonce;
};
export const selectBalance = (state: RootState) => {
  return state.wallet.balance;
};

export const selectAddress = (state: RootState) => {
  return state.auth.address;
};

export const selectTransactions = (state: RootState) => {
  return state.wallet.transactions;
};

export default walletSlice.reducer;
