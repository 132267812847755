/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import config from "config/config";

type ResetPasswordParams = {
  email: string;
  password: string;
  token: string;
};

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<boolean, ResetPasswordParams>({
    query: (params) => {
      return {
        url: config.api.resetPassword,
        method: "POST",
        body: {
          email: params.email,
          password: params.password,
          token: params.token,
        },
      };
    },
    transformResponse: (response: string, meta: any) =>
      meta.response.status === 200,
  });
