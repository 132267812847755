import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useLocalStorageState } from "shared/hooks/useLocalStorageState";

type Theme = {
  theme: "light" | "dark";
  toggleTheme: () => void;
};

export const ThemeContext = createContext<Theme>({} as Theme);

type Props = {
  children: ReactNode;
};

// Disabled auto-detect for now
// because light mode is not complete
const prefersDarkMode = true;
// Check if the user has set a preference for dark mode
// const prefersDarkMode = window.matchMedia(
//   "(prefers-color-scheme: dark)",
// ).matches;

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  // Get the theme from local storage or default to the user's preference
  const [theme, setTheme] = useLocalStorageState(
    "theme",
    prefersDarkMode ? "dark" : "light",
  );
  // Toggle the theme
  const toggleTheme = useCallback(() => {
    setTheme((prev: string) => (prev === "light" ? "dark" : "light"));
  }, [setTheme]);

  // Set the theme on the document body
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  // Change the theme when the user presses ctrl+t
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "t") {
        toggleTheme();
      }
    },
    [toggleTheme],
  );

  useEffect(() => {
    document.documentElement.addEventListener("keydown", handleKeyDown);
    return () => {
      document.documentElement.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const value = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};
