export type Country = {
  value: string;
  phoneCode: string;
  phoneMask: string | string[];
  label: string;
};

export const COUNTRY_OPTIONS = [
  {
    value: "Afghanistan",
    phoneCode: "+93",
    phoneMask: "##-###-####",
    label: "Afghanistan",
  },
  {
    value: "Aland Islands",
    phoneCode: "+358",
    phoneMask: "(###)###-##-##",
    label: "Aland Islands",
  },
  {
    value: "Albania",
    phoneCode: "+355",
    phoneMask: "(###)###-###",
    label: "Albania",
  },
  {
    value: "Algeria",
    phoneCode: "+213",
    phoneMask: "##-###-####",
    label: "Algeria",
  },
  {
    value: "American Samoa",
    phoneCode: "+1",
    phoneMask: "(684)###-####",
    label: "American Samoa",
  },
  {
    value: "Andorra",
    phoneCode: "+376",
    phoneMask: "###-###",
    label: "Andorra",
  },
  {
    value: "Angola",
    phoneCode: "+244",
    phoneMask: "(###)###-###",
    label: "Angola",
  },
  {
    value: "Anguilla",
    phoneCode: "+1",
    phoneMask: "(264)###-####",
    label: "Anguilla",
  },
  {
    value: "Antarctica",
    phoneCode: "+672",
    phoneMask: "1##-###",
    label: "Antarctica",
  },
  {
    value: "Antigua and Barbuda",
    phoneCode: "+1",
    phoneMask: "(268)###-####",
    label: "Antigua and Barbuda",
  },
  {
    value: "Argentina",
    phoneCode: "+54",
    phoneMask: "(###)###-####",
    label: "Argentina",
  },
  {
    value: "Armenia",
    phoneCode: "+374",
    phoneMask: "##-###-###",
    label: "Armenia",
  },
  {
    value: "Aruba",
    phoneCode: "+297",
    phoneMask: "###-####",
    label: "Aruba",
  },
  {
    value: "Ascension Island",
    phoneCode: "+247",
    phoneMask: "####",
    label: "Ascension Island",
  },
  {
    value: "Australia",
    phoneCode: "+61",
    phoneMask: "#-####-####",
    label: "Australia",
  },
  {
    value: "Austria",
    phoneCode: "+43",
    phoneMask: "(###)###-####",
    label: "Austria",
  },
  {
    value: "Azerbaijan",
    phoneCode: "+994",
    phoneMask: "##-###-##-##",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    phoneCode: "+1",
    phoneMask: "(242)###-####",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    phoneCode: "+973",
    phoneMask: "####-####",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    phoneCode: "+880",
    phoneMask: "1###-######",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    phoneCode: "+1",
    phoneMask: "(246)###-####",
    label: "Barbados",
  },
  {
    value: "Belarus",
    phoneCode: "+375",
    phoneMask: "(##)###-##-##",
    label: "Belarus",
  },
  {
    value: "Belgium",
    phoneCode: "+32",
    phoneMask: "(###)###-###",
    label: "Belgium",
  },
  {
    value: "Belize",
    phoneCode: "+501",
    phoneMask: "###-####",
    label: "Belize",
  },
  {
    value: "Benin",
    phoneCode: "+229",
    phoneMask: "##-##-####",
    label: "Benin",
  },
  {
    value: "Bermuda",
    phoneCode: "+1",
    phoneMask: "(441)###-####",
    label: "Bermuda",
  },
  {
    value: "Bhutan",
    phoneCode: "+975",
    phoneMask: ["17-###-###", "77-###-###", "#-###-###"],
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    phoneCode: "+591",
    phoneMask: "#-###-####",
    label: "Bolivia",
  },
  {
    value: "Bosnia and Herzegovina",
    phoneCode: "+387",
    phoneMask: ["##-####", "##-#####"],
    label: "Bosnia and Herzegovina",
  },
  {
    value: "Botswana",
    phoneCode: "+267",
    phoneMask: "##-###-###",
    label: "Botswana",
  },
  {
    value: "Brazil",
    phoneCode: "+55",
    phoneMask: ["(##)####-####", "(##)#####-####"],
    label: "Brazil",
  },
  {
    value: "British Indian Ocean Territory",
    phoneCode: "+246",
    phoneMask: "###-####",
    label: "British Indian Ocean Territory",
  },
  {
    value: "Brunei Darussalam",
    phoneCode: "+673",
    phoneMask: "###-####",
    label: "Brunei Darussalam",
  },
  {
    value: "Bulgaria",
    phoneCode: "+359",
    phoneMask: "(###)###-###",
    label: "Bulgaria",
  },
  {
    value: "Burkina Faso",
    phoneCode: "+226",
    phoneMask: "##-##-####",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    phoneCode: "+257",
    phoneMask: "##-##-####",
    label: "Burundi",
  },
  {
    value: "Cambodia",
    phoneCode: "+855",
    phoneMask: "##-###-###",
    label: "Cambodia",
  },
  {
    value: "Cameroon",
    phoneCode: "+237",
    phoneMask: "####-####",
    label: "Cameroon",
  },
  {
    value: "Canada",
    phoneCode: "+1",
    phoneMask: "(###)###-####",
    label: "Canada",
  },
  {
    value: "Cape Verde",
    phoneCode: "+238",
    phoneMask: "(###)##-##",
    label: "Cape Verde",
  },
  {
    value: "Cayman Islands",
    phoneCode: "+1",
    phoneMask: "(345)###-####",
    label: "Cayman Islands",
  },
  {
    value: "Central African Republic",
    phoneCode: "+236",
    phoneMask: "##-##-####",
    label: "Central African Republic",
  },
  {
    value: "Chad",
    phoneCode: "+235",
    phoneMask: "##-##-##-##",
    label: "Chad",
  },
  {
    value: "Chile",
    phoneCode: "+56",
    phoneMask: "#-####-####",
    label: "Chile",
  },
  {
    value: "China",
    phoneCode: "+86",
    phoneMask: ["(###)####-###", "(###)####-####", "##-#####-#####"],
    label: "China",
  },
  {
    value: "Christmas Island",
    phoneCode: "+61",
    phoneMask: "#-####-####",
    label: "Christmas Island",
  },
  {
    value: "Cocos (Keeling) Islands",
    phoneCode: "+61",
    phoneMask: "#-####-####",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "Colombia",
    phoneCode: "+57",
    phoneMask: "(###)###-####",
    label: "Colombia",
  },
  {
    value: "Comoros",
    phoneCode: "+269",
    phoneMask: "##-#####",
    label: "Comoros",
  },
  {
    value: "Congo",
    phoneCode: "+242",
    phoneMask: "##-#####",
    label: "Congo",
  },
  {
    value: "Cook Islands",
    phoneCode: "+682",
    phoneMask: "##-###",
    label: "Cook Islands",
  },
  {
    value: "Costa Rica",
    phoneCode: "+506",
    phoneMask: "####-####",
    label: "Costa Rica",
  },
  {
    value: "Croatia",
    phoneCode: "+385",
    phoneMask: "##-###-###",
    label: "Croatia",
  },
  {
    value: "Cuba",
    phoneCode: "+53",
    phoneMask: "#-###-####",
    label: "Cuba",
  },
  {
    value: "Cyprus",
    phoneCode: "+357",
    phoneMask: "##-###-###",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    phoneCode: "+420",
    phoneMask: "(###)###-###",
    label: "Czech Republic",
  },
  {
    value: "Democratic Republic of the Congo",
    phoneCode: "+243",
    phoneMask: "(###)###-###",
    label: "Democratic Republic of the Congo",
  },
  {
    value: "Denmark",
    phoneCode: "+45",
    phoneMask: "##-##-##-##",
    label: "Denmark",
  },
  {
    value: "Djibouti",
    phoneCode: "+253",
    phoneMask: "##-##-##-##",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    phoneCode: "+1",
    phoneMask: "(767)###-####",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    phoneCode: "+1",
    phoneMask: ["(809)###-####", "(829)###-####", "(849)###-####"],
    label: "Dominican Republic",
  },
  {
    value: "Ecuador",
    phoneCode: "+593",
    phoneMask: ["#-###-####", "##-###-####"],
    label: "Ecuador",
  },
  {
    value: "Egypt",
    phoneCode: "+20",
    phoneMask: "(###)###-####",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    phoneCode: "+503",
    phoneMask: "##-##-####",
    label: "El Salvador",
  },
  {
    value: "Equatorial Guinea",
    phoneCode: "+240",
    phoneMask: "##-###-####",
    label: "Equatorial Guinea",
  },
  {
    value: "Eritrea",
    phoneCode: "+291",
    phoneMask: "#-###-###",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    phoneCode: "+372",
    phoneMask: ["###-####", "####-####"],
    label: "Estonia",
  },
  {
    value: "Eswatini",
    phoneCode: "+268",
    phoneMask: "##-##-####",
    label: "Eswatini",
  },
  {
    value: "Ethiopia",
    phoneCode: "+251",
    phoneMask: "##-###-####",
    label: "Ethiopia",
  },
  {
    value: "Falkland Islands (Malvinas)",
    phoneCode: "+500",
    phoneMask: "#####",
    label: "Falkland Islands (Malvinas)",
  },
  {
    value: "Faroe Islands",
    phoneCode: "+298",
    phoneMask: "###-###",
    label: "Faroe Islands",
  },
  {
    value: "Fiji",
    phoneCode: "+679",
    phoneMask: "##-#####",
    label: "Fiji",
  },
  {
    value: "Finland",
    phoneCode: "+358",
    phoneMask: "(###)###-##-##",
    label: "Finland",
  },
  {
    value: "France",
    phoneCode: "+33",
    phoneMask: "(###)###-###",
    label: "France",
  },
  {
    value: "French Guiana",
    phoneCode: "+594",
    phoneMask: "#####-####",
    label: "French Guiana",
  },
  {
    value: "French Polynesia",
    phoneCode: "+689",
    phoneMask: "##-##-##",
    label: "French Polynesia",
  },
  {
    value: "Gabon",
    phoneCode: "+241",
    phoneMask: "#-##-##-##",
    label: "Gabon",
  },
  {
    value: "Gambia",
    phoneCode: "+220",
    phoneMask: "(###)##-##",
    label: "Gambia",
  },
  {
    value: "Georgia",
    phoneCode: "+995",
    phoneMask: "(###)###-###",
    label: "Georgia",
  },
  {
    value: "Germany",
    phoneCode: "+49",
    phoneMask: [
      "###-###",
      "(###)##-##",
      "(###)##-###",
      "(###)##-####",
      "(###)###-####",
      "(####)###-####",
    ],
    label: "Germany",
  },
  {
    value: "Ghana",
    phoneCode: "+233",
    phoneMask: "(###)###-###",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    phoneCode: "+350",
    phoneMask: "###-#####",
    label: "Gibraltar",
  },
  {
    value: "Greece",
    phoneCode: "+30",
    phoneMask: "(###)###-####",
    label: "Greece",
  },
  {
    value: "Greenland",
    phoneCode: "+299",
    phoneMask: "##-##-##",
    label: "Greenland",
  },
  {
    value: "Grenada",
    phoneCode: "+1",
    phoneMask: "(473)###-####",
    label: "Grenada",
  },
  {
    value: "Guadeloupe",
    phoneCode: "+590",
    phoneMask: "(###)###-###",
    label: "Guadeloupe",
  },
  {
    value: "Guam",
    phoneCode: "+1",
    phoneMask: "(671)###-####",
    label: "Guam",
  },
  {
    value: "Guatemala",
    phoneCode: "+502",
    phoneMask: "#-###-####",
    label: "Guatemala",
  },
  {
    value: "Guernsey",
    phoneCode: "+44",
    phoneMask: "(####)######",
    label: "Guernsey",
  },
  {
    value: "Guinea",
    phoneCode: "+224",
    phoneMask: "##-###-###",
    label: "Guinea",
  },
  {
    value: "Guinea-Bissau",
    phoneCode: "+245",
    phoneMask: "#-######",
    label: "Guinea-Bissau",
  },
  {
    value: "Guyana",
    phoneCode: "+592",
    phoneMask: "###-####",
    label: "Guyana",
  },
  {
    value: "Haiti",
    phoneCode: "+509",
    phoneMask: "##-##-####",
    label: "Haiti",
  },
  {
    value: "Holy See (Vatican City State)",
    phoneCode: "+39",
    phoneMask: "06 698#####",
    label: "Holy See (Vatican City State)",
  },
  {
    value: "Honduras",
    phoneCode: "+504",
    phoneMask: "####-####",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    phoneCode: "+852",
    phoneMask: "####-####",
    label: "Hong Kong",
  },
  {
    value: "Hungary",
    phoneCode: "+36",
    phoneMask: "(###)###-###",
    label: "Hungary",
  },
  {
    value: "Iceland",
    phoneCode: "+354",
    phoneMask: "###-####",
    label: "Iceland",
  },
  {
    value: "India",
    phoneCode: "+91",
    phoneMask: "(####)###-###",
    label: "India",
  },
  {
    value: "Indonesia",
    phoneCode: "+62",
    phoneMask: [
      "##-###-##",
      "##-###-###",
      "##-###-####",
      "(8##)###-###",
      "(8##)###-##-###",
    ],
    label: "Indonesia",
  },
  {
    value: "Iran",
    phoneCode: "+98",
    phoneMask: "(###)###-####",
    label: "Iran",
  },
  {
    value: "Iraq",
    phoneCode: "+924",
    phoneMask: "(###)###-####",
    label: "Iraq",
  },
  {
    value: "Ireland",
    phoneCode: "+353",
    phoneMask: "(###)###-###",
    label: "Ireland",
  },
  {
    value: "Isle of Man",
    phoneCode: "+44",
    phoneMask: "(####)######",
    label: "Isle of Man",
  },
  {
    value: "Israel",
    phoneCode: "+972",
    phoneMask: ["#-###-####", "5#-###-####"],
    label: "Israel",
  },
  {
    value: "Italy",
    phoneCode: "+39",
    phoneMask: "(###)####-###",
    label: "Italy",
  },
  {
    value: "Ivory Coast / Cote d'Ivoire",
    phoneCode: "+225",
    phoneMask: "##-###-###",
    label: "Ivory Coast / Cote d'Ivoire",
  },
  {
    value: "Jamaica",
    phoneCode: "+1",
    phoneMask: "(876)###-####",
    label: "Jamaica",
  },
  {
    value: "Japan",
    phoneCode: "+81",
    phoneMask: ["(###)###-###", "##-####-####"],
    label: "Japan",
  },
  {
    value: "Jersey",
    phoneCode: "+44",
    phoneMask: "(####)####-######",
    label: "Jersey",
  },
  {
    value: "Jordan",
    phoneCode: "+962",
    phoneMask: "#-####-####",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    phoneCode: "+77",
    phoneMask: ["(6##)###-##-##", "(7##)###-##-##"],
    label: "Kazakhstan",
  },
  {
    value: "Kenya",
    phoneCode: "+254",
    phoneMask: "###-######",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    phoneCode: "+686",
    phoneMask: "##-###",
    label: "Kiribati",
  },
  {
    value: "Korea, Democratic People's Republic of Korea",
    phoneCode: "+850",
    phoneMask: [
      "###-###",
      "####-####",
      "##-###-###",
      "###-####-###",
      "191-###-####",
      "####-#############",
    ],
    label: "Korea, Democratic People's Republic of Korea",
  },
  {
    value: "Korea, Republic of South Korea",
    phoneCode: "+82",
    phoneMask: "##-###-####",
    label: "Korea, Republic of South Korea",
  },
  {
    value: "Kosovo",
    phoneCode: "+383",
    phoneMask: ["##-###-###", "###-###-###"],
    label: "Kosovo",
  },
  {
    value: "Kuwait",
    phoneCode: "+965",
    phoneMask: "####-####",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    phoneCode: "+996",
    phoneMask: "(###)###-###",
    label: "Kyrgyzstan",
  },
  {
    value: "Laos",
    phoneCode: "+856",
    phoneMask: ["##-###-###", "(20##)###-###"],
    label: "Laos",
  },
  {
    value: "Latvia",
    phoneCode: "+371",
    phoneMask: "##-###-###",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    phoneCode: "+961",
    phoneMask: ["#-###-###", "##-###-###"],
    label: "Lebanon",
  },
  {
    value: "Lesotho",
    phoneCode: "+266",
    phoneMask: "#-###-####",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    phoneCode: "+231",
    phoneMask: "##-###-###",
    label: "Liberia",
  },
  {
    value: "Libya",
    phoneCode: "+218",
    phoneMask: ["##-###-###", "21-###-####"],
    label: "Libya",
  },
  {
    value: "Liechtenstein",
    phoneCode: "+423",
    phoneMask: "(###)###-####",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    phoneCode: "+370",
    phoneMask: "(###)##-###",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    phoneCode: "+352",
    phoneMask: "(###)###-###",
    label: "Luxembourg",
  },
  {
    value: "Macau",
    phoneCode: "+853",
    phoneMask: "####-####",
    label: "Macau",
  },
  {
    value: "Madagascar",
    phoneCode: "+261",
    phoneMask: "##-##-#####",
    label: "Madagascar",
  },
  {
    value: "Malawi",
    phoneCode: "+265",
    phoneMask: ["1-###-###", "#-####-####"],
    label: "Malawi",
  },
  {
    value: "Malaysia",
    phoneCode: "+60",
    phoneMask: ["#-###-###", "##-###-###", "(###)###-###", "##-###-####"],
    label: "Malaysia",
  },
  {
    value: "Maldives",
    phoneCode: "+960",
    phoneMask: "###-####",
    label: "Maldives",
  },
  {
    value: "Mali",
    phoneCode: "+223",
    phoneMask: "##-##-####",
    label: "Mali",
  },
  {
    value: "Malta",
    phoneCode: "+356",
    phoneMask: "####-####",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    phoneCode: "+692",
    phoneMask: "###-####",
    label: "Marshall Islands",
  },
  {
    value: "Martinique",
    phoneCode: "+596",
    phoneMask: "(###)##-##-##",
    label: "Martinique",
  },
  {
    value: "Mauritania",
    phoneCode: "+222",
    phoneMask: "##-##-####",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    phoneCode: "+230",
    phoneMask: "###-####",
    label: "Mauritius",
  },
  {
    value: "Mayotte",
    phoneCode: "+262",
    phoneMask: "#####-####",
    label: "Mayotte",
  },
  {
    value: "Mexico",
    phoneCode: "+52",
    phoneMask: ["##-##-####", "(###)###-####"],
    label: "Mexico",
  },
  {
    value: "Micronesia, Federated States of Micronesia",
    phoneCode: "+691",
    phoneMask: "###-####",
    label: "Micronesia, Federated States of Micronesia",
  },
  {
    value: "Moldova",
    phoneCode: "+373",
    phoneMask: "####-####",
    label: "Moldova",
  },
  {
    value: "Monaco",
    phoneCode: "+377",
    phoneMask: ["##-###-###", "(###)###-###"],
    label: "Monaco",
  },
  {
    value: "Mongolia",
    phoneCode: "+976",
    phoneMask: "##-##-####",
    label: "Mongolia",
  },
  {
    value: "Montenegro",
    phoneCode: "+382",
    phoneMask: "##-###-###",
    label: "Montenegro",
  },
  {
    value: "Montserrat",
    phoneCode: "+1",
    phoneMask: "(664)###-####",
    label: "Montserrat",
  },
  {
    value: "Morocco",
    phoneCode: "+212",
    phoneMask: "##-####-###",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    phoneCode: "+258",
    phoneMask: "##-###-###",
    label: "Mozambique",
  },
  {
    value: "Myanmar",
    phoneCode: "+95",
    phoneMask: ["###-###", "#-###-###", "##-###-###"],
    label: "Myanmar",
  },
  {
    value: "Namibia",
    phoneCode: "+224",
    phoneMask: "##-###-####",
    label: "Namibia",
  },
  {
    value: "Nauru",
    phoneCode: "+674",
    phoneMask: "###-####",
    label: "Nauru",
  },
  {
    value: "Nepal",
    phoneCode: "+977",
    phoneMask: "##-###-###",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    phoneCode: "+31",
    phoneMask: "##-###-####",
    label: "Netherlands",
  },
  {
    value: "Netherlands Antilles",
    phoneCode: "+599",
    phoneMask: ["###-####", "9###-####"],
    label: "Netherlands Antilles",
  },
  {
    value: "New Caledonia",
    phoneCode: "+687",
    phoneMask: "##-####",
    label: "New Caledonia",
  },
  {
    value: "New Zealand",
    phoneCode: "+24",
    phoneMask: ["#-###-###", "(###)###-###", "(###)###-####"],
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    phoneCode: "+505",
    phoneMask: "####-####",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    phoneCode: "+227",
    phoneMask: "##-##-####",
    label: "Niger",
  },
  {
    value: "Nigeria",
    phoneCode: "+234",
    phoneMask: ["##-###-##", "##-###-###", "(###)###-####"],
    label: "Nigeria",
  },
  {
    value: "Niue",
    phoneCode: "+683",
    phoneMask: "####",
    label: "Niue",
  },
  {
    value: "Norfolk Island",
    phoneCode: "+672",
    phoneMask: "3##-###",
    label: "Norfolk Island",
  },
  {
    value: "North Macedonia",
    phoneCode: "+389",
    phoneMask: "##-###-###",
    label: "North Macedonia",
  },
  {
    value: "Northern Mariana Islands",
    phoneCode: "+1",
    phoneMask: "(670)###-####",
    label: "Northern Mariana Islands",
  },
  {
    value: "Norway",
    phoneCode: "+47",
    phoneMask: "(###)##-###",
    label: "Norway",
  },
  {
    value: "Oman",
    phoneCode: "+968",
    phoneMask: "##-###-###",
    label: "Oman",
  },
  {
    value: "Pakistan",
    phoneCode: "+92",
    phoneMask: "(###)###-####",
    label: "Pakistan",
  },
  {
    value: "Palau",
    phoneCode: "+680",
    phoneMask: "###-####",
    label: "Palau",
  },
  {
    value: "Palestine",
    phoneCode: "+970",
    phoneMask: "##-###-####",
    label: "Palestine",
  },
  {
    value: "Panama",
    phoneCode: "+507",
    phoneMask: "###-####",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    phoneCode: "+675",
    phoneMask: "(###)##-###",
    label: "Papua New Guinea",
  },
  {
    value: "Paraguay",
    phoneCode: "+595",
    phoneMask: "(###)###-###",
    label: "Paraguay",
  },
  {
    value: "Peru",
    phoneCode: "+51",
    phoneMask: "(###)###-###",
    label: "Peru",
  },
  {
    value: "Philippines",
    phoneCode: "+63",
    phoneMask: "(###)###-####",
    label: "Philippines",
  },
  {
    value: "Pitcairn",
    phoneCode: "+870",
    phoneMask: "###-###-###",
    label: "Pitcairn",
  },
  {
    value: "Poland",
    phoneCode: "+48",
    phoneMask: "(###)###-###",
    label: "Poland",
  },
  {
    value: "Portugal",
    phoneCode: "+351",
    phoneMask: "##-###-####",
    label: "Portugal",
  },
  {
    value: "Puerto Rico",
    phoneCode: "+1",
    phoneMask: ["(787) ### ####", "(939) ### ####"],
    label: "Puerto Rico",
  },
  {
    value: "Qatar",
    phoneCode: "+974",
    phoneMask: "####-####",
    label: "Qatar",
  },
  {
    value: "Reunion",
    phoneCode: "+262",
    phoneMask: "#####-####",
    label: "Reunion",
  },
  {
    value: "Romania",
    phoneCode: "+40",
    phoneMask: "###-###-###",
    label: "Romania",
  },
  {
    value: "Russia",
    phoneCode: "+7",
    phoneMask: "(###)###-##-##",
    label: "Russia",
  },
  {
    value: "Rwanda",
    phoneCode: "+250",
    phoneMask: "(###)###-###",
    label: "Rwanda",
  },
  {
    value: "Saint Barthelemy",
    phoneCode: "+590",
    phoneMask: "###-##-##-##",
    label: "Saint Barthelemy",
  },
  {
    value: "Saint Helena",
    phoneCode: "+290",
    phoneMask: "####",
    label: "Saint Helena",
  },
  {
    value: "Saint Kitts and Nevis",
    phoneCode: "+1",
    phoneMask: "(869)###-####",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "Saint Lucia",
    phoneCode: "+1",
    phoneMask: "(758)###-####",
    label: "Saint Lucia",
  },
  {
    value: "Saint Martin",
    phoneCode: "+590",
    phoneMask: "(###)###-###",
    label: "Saint Martin",
  },
  {
    value: "Saint Pierre and Miquelon",
    phoneCode: "+508",
    phoneMask: "##-####",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Saint Vincent and the Grenadines",
    phoneCode: "+1",
    phoneMask: "(784)###-####",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "Samoa",
    phoneCode: "+685",
    phoneMask: "##-####",
    label: "Samoa",
  },
  {
    value: "San Marino",
    phoneCode: "+378",
    phoneMask: "####-######",
    label: "San Marino",
  },
  {
    value: "Sao Tome and Principe",
    phoneCode: "+239",
    phoneMask: "##-#####",
    label: "Sao Tome and Principe",
  },
  {
    value: "Saudi Arabia",
    phoneCode: "+966",
    phoneMask: ["#-###-####", "5#-####-####"],
    label: "Saudi Arabia",
  },
  {
    value: "Senegal",
    phoneCode: "+221",
    phoneMask: "##-###-####",
    label: "Senegal",
  },
  {
    value: "Serbia",
    phoneCode: "+381",
    phoneMask: "##-###-####",
    label: "Serbia",
  },
  {
    value: "Seychelles",
    phoneCode: "+248",
    phoneMask: "#-###-###",
    label: "Seychelles",
  },
  {
    value: "Sierra Leone",
    phoneCode: "+232",
    phoneMask: "##-######",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    phoneCode: "+65",
    phoneMask: "####-####",
    label: "Singapore",
  },
  {
    value: "Sint Maarten",
    phoneCode: "+1",
    phoneMask: "(721)###-####",
    label: "Sint Maarten",
  },
  {
    value: "Slovakia",
    phoneCode: "+421",
    phoneMask: "(###)###-###",
    label: "Slovakia",
  },
  {
    value: "Slovenia",
    phoneCode: "+386",
    phoneMask: "##-###-###",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    phoneCode: "+677",
    phoneMask: ["#####", "###-####"],
    label: "Solomon Islands",
  },
  {
    value: "Somalia",
    phoneCode: "+252",
    phoneMask: ["#-###-###", "##-###-###"],
    label: "Somalia",
  },
  {
    value: "South Africa",
    phoneCode: "+27",
    phoneMask: "##-###-####",
    label: "South Africa",
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    phoneCode: "+500",
    phoneMask: "#####",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "South Sudan",
    phoneCode: "+211",
    phoneMask: "##-###-####",
    label: "South Sudan",
  },
  {
    value: "Spain",
    phoneCode: "+34",
    phoneMask: "(###)###-###",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    phoneCode: "+94",
    phoneMask: "##-###-####",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    phoneCode: "+249",
    phoneMask: "##-###-####",
    label: "Sudan",
  },
  {
    value: "Suriname",
    phoneCode: "+597",
    phoneMask: ["###-###", "###-####"],
    label: "Suriname",
  },
  {
    value: "Svalbard and Jan Mayen",
    phoneCode: "+47",
    phoneMask: "(###)##-###",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "Sweden",
    phoneCode: "+46",
    phoneMask: "##-###-####",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    phoneCode: "+41",
    phoneMask: "##-###-####",
    label: "Switzerland",
  },
  {
    value: "Syrian Arab Republic",
    phoneCode: "+963",
    phoneMask: "##-####-###",
    label: "Syrian Arab Republic",
  },
  {
    value: "Taiwan",
    phoneCode: "+886",
    phoneMask: ["####-####", "#-####-####"],
    label: "Taiwan",
  },
  {
    value: "Tajikistan",
    phoneCode: "+992",
    phoneMask: "##-###-####",
    label: "Tajikistan",
  },
  {
    value: "Tanzania, United Republic of Tanzania",
    phoneCode: "+255",
    phoneMask: "##-###-####",
    label: "Tanzania, United Republic of Tanzania",
  },
  {
    value: "Thailand",
    phoneCode: "+66",
    phoneMask: ["##-###-###", "##-###-####"],
    label: "Thailand",
  },
  {
    value: "Timor-Leste",
    phoneCode: "+670",
    phoneMask: ["###-####", "77#-#####", "78#-#####"],
    label: "Timor-Leste",
  },
  {
    value: "Togo",
    phoneCode: "+228",
    phoneMask: "##-###-###",
    label: "Togo",
  },
  {
    value: "Tokelau",
    phoneCode: "+690",
    phoneMask: "####",
    label: "Tokelau",
  },
  {
    value: "Tonga",
    phoneCode: "+676",
    phoneMask: "#####",
    label: "Tonga",
  },
  {
    value: "Trinidad and Tobago",
    phoneCode: "+1",
    phoneMask: "(868)###-####",
    label: "Trinidad and Tobago",
  },
  {
    value: "Tunisia",
    phoneCode: "+216",
    phoneMask: "##-###-###",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    phoneCode: "+90",
    phoneMask: "(###)###-####",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    phoneCode: "+993",
    phoneMask: "#-###-####",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    phoneCode: "+1",
    phoneMask: "(249)###-###",
    label: "Turks and Caicos Islands",
  },
  {
    value: "Tuvalu",
    phoneCode: "+688",
    phoneMask: ["2####", "90####"],
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    phoneCode: "+256",
    phoneMask: "(###)###-###",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    phoneCode: "+380",
    phoneMask: "(##)###-##-##",
    label: "Ukraine",
  },
  {
    value: "United Arab Emirates",
    phoneCode: "+971",
    phoneMask: ["#-###-####", "5#-###-####"],
    label: "United Arab Emirates",
  },
  {
    value: "United Kingdom",
    phoneCode: "+44",
    phoneMask: "##-####-####",
    label: "United Kingdom",
  },
  {
    value: "United States of America",
    phoneCode: "+1",
    phoneMask: "(###)###-####",
    label: "United States of America",
  },
  {
    value: "Uruguay",
    phoneCode: "+598",
    phoneMask: "#-###-##-##",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    phoneCode: "+998",
    phoneMask: "##-###-####",
    label: "Uzbekistan",
  },
  {
    value: "Vanuatu",
    phoneCode: "+678",
    phoneMask: ["#####", "##-#####"],
    label: "Vanuatu",
  },
  {
    value: "Venezuela",
    phoneCode: "+58",
    phoneMask: "(###)###-####",
    label: "Venezuela",
  },
  {
    value: "Vietnam",
    phoneCode: "+84",
    phoneMask: ["##-####-###", "(###)####-###"],
    label: "Vietnam",
  },
  {
    value: "Virgin Islands, British",
    phoneCode: "+1",
    phoneMask: "(284)###-####",
    label: "Virgin Islands, British",
  },
  {
    value: "Virgin Islands, U.S.",
    phoneCode: "+1",
    phoneMask: "(340)###-####",
    label: "Virgin Islands, U.S.",
  },
  {
    value: "Wallis and Futuna",
    phoneCode: "+681",
    phoneMask: "##-####",
    label: "Wallis and Futuna",
  },
  {
    value: "Yemen",
    phoneCode: "+967",
    phoneMask: ["#-###-###", "##-###-###", "###-###-###"],
    label: "Yemen",
  },
  {
    value: "Zambia",
    phoneCode: "+260",
    phoneMask: "##-###-####",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    phoneCode: "+263",
    phoneMask: "#-######",
    label: "Zimbabwe",
  },
];
