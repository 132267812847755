/* eslint-disable @typescript-eslint/no-explicit-any */
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { RegisterUserDto } from "features/api/apiSchemas";

import config from "config/config";

type RegisterUserArgs = {
  firstName: string;
  lastName: string;
  country: string;
  phoneNumber: string;
  email: string;
  password: string;
};

type RegisterUserResponse = {
  success: boolean;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<RegisterUserResponse, RegisterUserArgs>({
    query: (args) => {
      const body: RegisterUserDto = {
        first_name: args.firstName,
        last_name: args.lastName,
        country: args.country,
        phone_number: args.phoneNumber,
        email: args.email.toLowerCase(),
        password: args.password,
      };

      return {
        url: config.api.registerUsersBasic,
        method: "POST",
        body,
      };
    },
    transformResponse: (response: string, meta: any) => {
      const success = meta.response.status === 200;
      return {
        success,
      };
    },
  });
