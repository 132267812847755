import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button/Button";
import SectionHeader from "components/SectionHeader/SectionHeader";
import Spinner from "components/Spinner/Spinner";
import {
  getAllUserDetails,
  selectIsLoading,
} from "features/Wallet/walletSlice";
import useDisclosure from "shared/hooks/useDisclosure";

import BalanceCardContainer from "./Balance/BalanceCard/BalanceCardsContainer";
import DepositModal from "./DepositModal/DepositModal";
import TransactionsGrid from "./TransactionsGrid/TransactionsGrid";
import TransferModal from "./TransferModal/TransferModal";

import s from "./Wallet.module.scss";

const Wallet: React.FC = () => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(selectIsLoading);
  const dispatch = useAppDispatch();

  const {
    isOpen: isTransferModalOpen,
    onOpen: onTransferModalOpen,
    onClose: onTransferModalClose,
  } = useDisclosure();

  const {
    isOpen: isDepositModalOpen,
    onOpen: onDepositModalOpen,
    onClose: onDepositModalClose,
  } = useDisclosure();

  useEffect(() => {
    dispatch(getAllUserDetails());
  }, [dispatch]);

  const headerAction = (
    <>
      <Button onClick={onDepositModalOpen} secondary size="medium">
        {t("deposit")}
      </Button>
      <Button onClick={onTransferModalOpen} size="medium">
        {t("transfer")}
      </Button>
    </>
  );

  return isLoading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner
        size="large"
        message={t("loadingBlockchainData").toString()}
        subMessage={t("loadingSubMessage").toString()}
        gap={16}
      />
    </div>
  ) : (
    <>
      <TransferModal
        isOpen={isTransferModalOpen}
        onClose={onTransferModalClose}
      />
      <DepositModal isOpen={isDepositModalOpen} onClose={onDepositModalClose} />
      <div className={s.root}>
        <div className={s.headerArea}>
          <SectionHeader
            title={t("wallet")}
            action={headerAction}
          ></SectionHeader>
          <BalanceCardContainer />
        </div>
        <div className={s.content}>
          <TransactionsGrid />
        </div>
      </div>
    </>
  );
};

export default Wallet;
