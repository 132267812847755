import cx from "classnames";

import { IMenu } from "./Menu.d";

import s from "./Menu.module.scss";

const Menu: React.FC<IMenu> = ({ items, style }) => {
  return (
    <div className={s.root} style={style}>
      {items.map((item) => (
        <button
          className={cx(s.menuButton, item.error && s.error)}
          onClick={item.onClick}
          key={item.id}
        >
          {item.icon}
          <span>{item.label}</span>
        </button>
      ))}
    </div>
  );
};

export default Menu;
