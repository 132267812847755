import React, { useMemo } from "react";
import classNames from "classnames";

import { DashboardIcon, IncomingIcon, OutgoingIcon } from "components/icons";

import s from "./BalanceCards.module.scss";

export enum CardType {
  BALANCE = "balance",
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

interface BalanceCardProps {
  type: CardType;
  amount: number;
}

const BalanceCard: React.FC<BalanceCardProps> = ({ type, amount }) => {
  const { icon, text } = useMemo(() => {
    switch (type) {
      case CardType.BALANCE:
        return { icon: <DashboardIcon />, text: "Active Balance" };
      case CardType.INCOMING:
        return { icon: <IncomingIcon />, text: "Total Received" };
      case CardType.OUTGOING:
        return { icon: <OutgoingIcon />, text: "Total Sent" };

      default:
        return { icon: null, text: "" };
    }
  }, [type]);

  return (
    <div className={classNames(s.card, s[type])}>
      <div className={s.left}>
        {icon}
        <p>{text}</p>
      </div>
      <div className={s.right}>
        <p className={s.amount}>{amount}</p>
        <p className={s.currency}>CSOV</p>
      </div>
    </div>
  );
};

export default BalanceCard;
