import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Logo from "components/Logo/Logo";
import Textlink from "components/Textlink/Textlink";
import { selectIsLoggedIn, verifyToken } from "features/Auth/authSlice";
import BaseLayout from "layouts/BaseLayout/BaseLayout";

import s from "./VerifiedEmail.module.scss";

import config from "config/config";

const VerifiedEmail: React.FC = () => {
  const { t } = useTranslation("account");
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    navigate(config.routes.home);
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    dispatch(verifyToken(token ?? ""));
  }, [dispatch, token]);

  return (
    <BaseLayout>
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.header}>
            <Logo size={170} withBrand />
          </div>
          <div className={s.content}>
            <Trans
              i18nKey="account:verifiedEmail"
              components={[
                <Textlink
                  key={"verified"}
                  to={config.routes.login}
                  text={t("login")}
                />,
              ]}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default VerifiedEmail;
