import cx from "classnames";

import s from "./TransactionsGrid.module.scss";

type ITransactionAmount = {
  amount: number;
  isIncoming: boolean;
};

const TransactionAmount = ({ amount, isIncoming }: ITransactionAmount) => {
  return (
    <div>
      <span
        className={cx({
          [s.incoming]: isIncoming,
          [s.outgoing]: !isIncoming,
        })}
      >
        {isIncoming ? "+" : "-"}
        {amount}
      </span>
      <span className={s.currency}> CSOV</span>
    </div>
  );
};

export default TransactionAmount;
