import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

import { ThemeProvider } from "shared/contexts/Theme.context";

import RouteComponent from "./RouteComponent";

import "styles/global.scss";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FFF",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "DM Sans",
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontWeightLight: 200,
    fontWeightMedium: 500,
  },
});

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <MuiThemeProvider theme={theme}>
          <RouteComponent />
        </MuiThemeProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
