import { GridColDef } from "@mui/x-data-grid";

import { stripValue } from "shared/utils/csov";
import { formatDate } from "shared/utils/dates";

import TransactionAmount from "./TransactionAmount";
import TransactionType from "./TransactionType";

export const columns: GridColDef[] = [
  {
    field: "type",
    headerName: "Type",
    width: 32,
    renderCell: (params) => {
      return <TransactionType isIncoming={params.row.isIncoming} />;
    },
  },
  {
    field: "to",
    headerName: "Receiver",
    flex: 1,
  },
  {
    field: "from",
    headerName: "Sender",
    flex: 1,
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 200,
    valueGetter: (params) => {
      return params.row.blockTime;
    },
    sortingOrder: ["desc", "asc"],
    sortComparator: (v1, v2) => {
      return v1 - v2;
    },
    renderCell: (params) => (
      <div>
        <p>{formatDate(params.row.blockTime)}</p>
        <p style={{ fontSize: "12px", color: "var(--text-faded)" }}>
          Block number: {params.row.blockNumber}
        </p>
      </div>
    ),
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 180,
    valueGetter: (params) => {
      const isIncoming = params.row.isIncoming;
      const strippedValue = stripValue(params.value);
      return { value: strippedValue, isIncoming: isIncoming };
    },
    sortComparator: (v1, v2) => {
      const val1 = v1.isIncoming ? v1.value : -1 * v1.value;
      const val2 = v2.isIncoming ? v2.value : -1 * v2.value;
      return val1 - val2;
    },
    renderCell: (params) => {
      return (
        <TransactionAmount
          amount={params.value.value}
          isIncoming={params.value.isIncoming}
        />
      );
    },
  },
];
