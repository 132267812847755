import { api } from "features/api";

import acceptEula from "./acceptEula";
import changePassword from "./changePassword";
import resetPassword from "./resetPassword";
import resetPasswordInit from "./resetPasswordInit";
import updateUser from "./updateUser";
import verifyToken from "./verifyToken";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateUser: updateUser(build),
    changePassword: changePassword(build),
    resetPasswordInit: resetPasswordInit(build),
    resetPassword: resetPassword(build),
    verifyToken: verifyToken(build),
    acceptEula: acceptEula(build),
  }),
});
